import { convertPropertyName } from "@shared/utils/convert-property-name"

/**
 * Mark a model property as serialisable. Properties with this decorator will be serialised automatically.
 * The decorator will automatically try to convert the bound property's name to a snake_case name.
 *
 * @param key [Optional] Key that will be used instead of the automatically generated one.
 **/
export function Serialise(key?: string) {
  return (target: any, bindingPropertyName?: string): any => {
    if (!target._serialiseFields) {
      target._serialiseFields = {}
    }
    const prefix = target.constructor.name
    if (bindingPropertyName != undefined) {
      var prop = convertPropertyName(bindingPropertyName, key)
      target._serialiseFields[`${prefix}#${bindingPropertyName}`] = prop
    }
  }
}
