// Imports, e.g.: import { MyComponent } from './my/my.component';
import { BannerComponent } from "./banner/banner.component"
import { ErrorMessageComponent } from "./error-message/error-message.component"
import { LoadingComponent } from "./loading/loading.component"
import { LoginComponent } from "./login/login.component"
import { VersionSelectorComponent } from "./version-selector/version-selector.component"

// Array exports
export const components = [
  LoadingComponent,
  LoginComponent,
  BannerComponent,
  ErrorMessageComponent,
  VersionSelectorComponent,
]

// Exports, e.g.: export * from './my/my.component';
export * from "./banner/banner.component"
export * from "./error-message/error-message.component"
export * from "./loading/loading.component"
export * from "./login/login.component"
export * from "./version-selector/version-selector.component"
