import { Field } from "@shared/decorators"
import { PIModel } from "@shared/models"

const JANUARY_MONTH_NUMBER = 0
const DECEMBER_MONTH_NUMBER = 11

export class ProfileWorkload extends PIModel {
  @Field() profileId = -1
  @Field() startMonth = JANUARY_MONTH_NUMBER
  @Field() endMonth = DECEMBER_MONTH_NUMBER
  @Field() shiftTypeId = -1
  @Field() shiftTypeName = ""
  @Field() workload = 1

  static override Sort(wl1: ProfileWorkload, wl2: ProfileWorkload): number {
    if (wl1.shiftTypeId === wl2.shiftTypeId) {
      return wl1.startMonth - wl2.startMonth
    } else {
      return wl1.shiftTypeId - wl2.shiftTypeId
    }
  }
}

export const monthNumberToMonthMap: Record<number, string> = {
  0: $localize`:@@january:Januari`,
  1: $localize`:@@february:Februari`,
  2: $localize`:@@march:Maart`,
  3: $localize`:@@april:April`,
  4: $localize`:@@may:Mei`,
  5: $localize`:@@june:Juni`,
  6: $localize`:@@july:Juli`,
  7: $localize`:@@august:Augustus`,
  8: $localize`:@@september:September`,
  9: $localize`:@@october:Oktober`,
  10: $localize`:@@november:November`,
  11: $localize`:@@december:December`,
}
