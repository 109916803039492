<app-banner>
  <div
    *ngIf="!invalidToken && !resetSuccess; else invalidToken ? invalidTokenMessage : successMessage"
  >
    <div class="section ml-auto mr-auto">
      <h1 class="text-primary" i18n="@@passwordReset.title">Wachtwoord instellen</h1>
      <ng-container
        *ngTemplateOutlet="errorList; context: { errorlist: errors['general'] }"
      ></ng-container>
      <div class="input-container">
        <label for="resetEmailInput" i18n="@@email">E-mailadres</label>
        <input
          pInputText
          id="resetEmailInput"
          type="email"
          class="w-full"
          [ngModel]="resetInfo.email"
          [class.ng-invalid]="submitted && errors['email'] !== undefined"
          [class.ng-dirty]="submitted && errors['email'] !== undefined"
          [readonly]="true"
        />
      </div>
      <ng-container
        *ngTemplateOutlet="errorList; context: { errorlist: errors['email'] }"
      ></ng-container>
      <div class="input-container">
        <label for="resetPassword1Input" i18n="@@password">Wachtwoord</label>
        <input
          #pw1
          pInputText
          id="resetPassword1Input"
          type="password"
          class="w-full"
          required
          [class.ng-invalid]="submitted && errors['password1'] !== undefined"
          [class.ng-dirty]="submitted && errors['password1'] !== undefined"
          [(ngModel)]="resetInfo.password1"
          (ngModelChange)="validate()"
        />
      </div>
      <ng-container
        *ngTemplateOutlet="errorList; context: { errorlist: errors['password1'] }"
      ></ng-container>
      <div class="input-container">
        <label for="resetPassword2Input" i18n="@@passwordRepeat">Herhaal wachtwoord</label>
        <input
          #pw2
          pInputText
          id="resetPassword2Input"
          type="password"
          class="w-full"
          required
          [class.ng-invalid]="submitted && errors['password2'] !== undefined"
          [class.ng-dirty]="submitted && errors['password2'] !== undefined"
          [(ngModel)]="resetInfo.password2"
          (ngModelChange)="validate()"
        />
      </div>
      <ng-container
        *ngTemplateOutlet="errorList; context: { errorlist: errors['password2'] }"
      ></ng-container>
      <div class="mt-3 flex flex-row-reverse">
        <button
          type="submit"
          class="button-primary"
          [disabled]="isLoading || (submitted && (!pw1.checkValidity() || !pw2.checkValidity()))"
          (click)="onSubmit()"
          (keypress)="onSubmit()"
        >
          <ng-container *ngIf="!isLoading; else loading" i18n="@@button.confirm"
            >Bevestigen</ng-container
          >
        </button>
      </div>
    </div>
  </div>
</app-banner>

<ng-template #invalidTokenMessage>
  <div class="flex items-center justify-center">
    <div class="w-1/2">
      <h2 class="pl-2">
        <fa-icon icon="circle-exclamation" class="pr-2"></fa-icon>
        <span i18n="@@passwordReset.invalidToken.title">Ongeldig wachtwoordtoken</span>
      </h2>
      <a routerLink="/" class="fluid center aligned" i18n="@@pageNotFound.returnToHome"
        >Klik hier om terug te keren naar de hoofdpagina</a
      >
    </div>
  </div>
</ng-template>

<ng-template #successMessage>
  <div class="flex items-center justify-center">
    <div class="w-1/2">
      <h2 class="pl-2">
        <fa-icon icon="check" class="pr-2"></fa-icon>
        <span i18n="@@passwordReset.success.title">Wachtwoord successvol ingesteld</span>
      </h2>
      <div class="center aligned mb-3" i18n="@@passwordReset.success.info"
        >Je kan nu inloggen met je nieuwe wachtwoord</div
      >
      <a routerLink="/" class="fluid center aligned" i18n="@@pageNotFound.returnToHome"
        >Klik hier om terug te keren naar de hoofdpagina</a
      >
    </div>
  </div>
</ng-template>

<ng-template #errorList let-errorlist="errorlist">
  <div [hidden]="!submitted" class="ml-2" *ngFor="let error of errorlist; trackBy: trackValue">
    <small class="text-danger">{{ error }}</small>
  </div>
</ng-template>

<ng-template #loading><app-loading></app-loading></ng-template>
