import { Injectable } from "@angular/core"
import { BehaviorSubject, catchError, Observable, of, tap } from "rxjs"
import { Unit, UnitUser } from "@planner/models"
import { PIEndpoint, PIEndpointBuilder } from "@shared/utils/endpoint-builder"
import { environment } from "@env"

@Injectable({
  providedIn: "root",
})
export class UnitService {
  private endPoint: PIEndpoint<Unit>
  private unitUsersEndPoint: PIEndpoint<UnitUser>
  // Internal subject to hold the current unit
  private _currentUnit = new BehaviorSubject<Unit | undefined>(undefined)
  // Internal id of the current unit, used to retrieve the correct value
  private _currentUnitId = 0
  // Observable version of the subject used for readonly subscriptions
  currentUnit = this._currentUnit.asObservable()

  constructor(private endPointBuilder: PIEndpointBuilder) {
    this.endPoint = this.endPointBuilder.initialise(
      Unit,
      environment.api,
      (data: { isDetail: boolean }) => {
        if (data?.isDetail) {
          return `/units/${this._currentUnitId}`
        } else {
          return `/units/`
        }
      },
    )
    this.unitUsersEndPoint = this.endPointBuilder.initialise(
      UnitUser,
      environment.api,
      (data: { id: number }) => {
        if (data?.id) {
          return `/units/${this._currentUnitId}/users/${data.id}`
        } else {
          return `/units/${this._currentUnitId}/users/`
        }
      },
    )
  }

  switchCurrentUnitTo(unitId?: number): Observable<Unit | undefined> {
    /**
        Sets the current unit
        @param {number | undefined} unitId: the id of the requested current unit. Undefined is used to reset the unit
        @returns {Observable<Unit | undefined>} unit: the requested unit
        If the id is invalid, an error can be returned in the observable's error channel.
        */
    this._currentUnitId = unitId ?? 0
    if (this._currentUnitId === 0) {
      this._currentUnit.next(undefined)
      return of(undefined)
    }
    if (this.getCurrentUnit()?.id === this._currentUnitId) {
      return of(this.getCurrentUnit())
    }
    return this.getUnit().pipe(
      tap((value) => this._currentUnit.next(value)),
      catchError((err) => {
        this._currentUnitId = 0
        this._currentUnit.next(undefined)
        throw err
      }),
    )
  }

  getCurrentUnitId(): number {
    // Always get from subject since internal id is set before that is updated
    return this._currentUnit.getValue()?.id ?? 0
  }

  getCurrentUnit(): Unit | undefined {
    /**
     * @returns the current value of the unit without subscribing for future updates
     */
    return this._currentUnit.getValue()
  }

  getAllUnits = () => this.endPoint.get() as Observable<Unit[]>
  getUnit = () => this.endPoint.get({ url: { isDetail: true } }) as Observable<Unit>
  createUnit = (unit: Unit) => this.endPoint.post(unit)
  updateUnit = (unit: Unit) => this.endPoint.patch(unit, { url: { isDetail: true } })
  deleteUnit = () => this.endPoint.delete({ url: { isDetail: true } })

  getAllUnitUsers = () => this.unitUsersEndPoint.get() as Observable<UnitUser[]>
  addUnitUser = (unitUser: UnitUser) => this.unitUsersEndPoint.post(unitUser)
  updateUnitUser = (unitUser: UnitUser) =>
    this.unitUsersEndPoint.patch(unitUser, { url: { id: unitUser.id } })
  deleteUnitUser = (unitUserId: number) =>
    this.unitUsersEndPoint.delete({ url: { id: unitUserId } })
}
