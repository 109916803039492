export function LocalStorage<Type = string>(key?: string): PropertyDecorator {
  return (target: Object, propertyKey: string | symbol) => {
    // eslint-disable-next-line no-extra-parens
    let defaultValue = (target as Record<string | symbol, any>)[propertyKey]
    let lsKey = key ?? `priorit.${target.constructor.name}.${String(propertyKey)}`
    Object.defineProperty(target, propertyKey, {
      get(): Type {
        return localStorage.getItem(lsKey) ?? defaultValue
      },
      set(newValue: Type | string): void {
        localStorage.setItem(lsKey, String(newValue))
      },
    })
  }
}
