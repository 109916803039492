import { HttpErrorResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { BehaviorSubject, forkJoin, Observable, of, Subject, switchMap, throwError } from "rxjs"
import { catchError, finalize, map, tap } from "rxjs/operators"
import { Planning } from "@planner/models"
import { PlanningService } from "@core/services"
import { LocalStorage } from "@shared/decorators"
import { User } from "@shared/models"
import { PIEndpoint, PIEndpointBuilder } from "@shared/utils/endpoint-builder"
import {
  APIError,
  ErrorStatus,
  LoginData,
  PasswordResetConfirmData,
  PasswordResetData,
  TokenData,
  TokenLoginData,
} from "@public/models"
import { environment } from "@env"

interface LoginParams {
  token: boolean
}
interface LogoutParams {
  all: boolean
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public activeUser = new BehaviorSubject<User | undefined>(undefined)
  public loginRedirect?: string

  private loginEndpoint: PIEndpoint<TokenData>
  private logoutEndpoint: PIEndpoint<void>
  private requestPasswordResetEndpoint: PIEndpoint<void>
  private confirmPasswordResetEndpoint: PIEndpoint<void>
  private tokenUserEndpoint: PIEndpoint<User>

  @LocalStorage(environment.tokens.sessionToken) private sessionToken =
    localStorage.getItem(environment.tokens.sessionToken) ?? ""
  @LocalStorage(environment.tokens.sessionExpiry) private sessionExpiry =
    localStorage.getItem(environment.tokens.sessionExpiry) ?? ""
  @LocalStorage(environment.tokens.loginToken) private loginToken =
    localStorage.getItem(environment.tokens.loginToken) ?? ""
  @LocalStorage(environment.tokens.loginExpiry) private loginExpiry =
    localStorage.getItem(environment.tokens.loginExpiry) ?? ""

  constructor(private endPointBuilder: PIEndpointBuilder) {
    this.loginEndpoint = endPointBuilder.initialise(
      TokenData,
      environment.api,
      (data: LoginParams) => {
        return data?.token ? "/auth/tokenlogin/" : "/auth/login/"
      },
    )
    this.logoutEndpoint = endPointBuilder.initialiseVoid(environment.api, (data: LogoutParams) => {
      return data?.all ? "/auth/logout-all/" : "/auth/logout/"
    })
    this.requestPasswordResetEndpoint = endPointBuilder.initialiseVoid(
      environment.api,
      "/auth/reset-password/",
    )
    this.confirmPasswordResetEndpoint = endPointBuilder.initialiseVoid(
      environment.api,
      "/auth/confirm-reset-password/",
    )
    this.tokenUserEndpoint = endPointBuilder.initialise(User, environment.api, "/auth/user/")
  }

  getSessionToken(): string | undefined {
    return this.sessionToken !== "" ? this.sessionToken : undefined
  }

  login(loginData: LoginData): Observable<User> {
    return this.loginEndpoint.post(loginData).pipe(
      tap<TokenData>((token) => this.storeSession(token)),
      map<TokenData, User>((token) => token.user),
      tap<User>((user) => this.activeUser.next(user)),
    )
  }

  logout(): Observable<void> {
    // It might be enough to only delete the refresh token, this needs to be tested!
    if (!this.isLoggedIn()) {
      this.clearUser()
      return of(undefined)
    }
    return this.logoutEndpoint.post().pipe(finalize(() => this.clearUser()))
  }

  private clearUser(): void {
    this.clearStorage()
    this.activeUser.next(undefined)
  }

  resetPassword(data: PasswordResetData): Observable<void> {
    return this.requestPasswordResetEndpoint.post(data).pipe(catchError(this.handleAPIErrors))
  }

  confirmPasswordReset(data: PasswordResetConfirmData): Observable<void> {
    return this.confirmPasswordResetEndpoint.post(data).pipe(catchError(this.handleAPIErrors))
  }

  handleAPIErrors(error: HttpErrorResponse) {
    try {
      const apiError = new APIError(error)
      return throwError(() => apiError)
    } catch {
      // If it's not an api error, just throw it as-is
      return throwError(() => error)
    }
  }

  tryAutoLogin(forceRefresh = false): Observable<boolean> {
    if (!forceRefresh && this.isLoggedIn()) {
      return of(true)
    } else if (this.isCurrentSessionValid()) {
      console.debug("[AuthService] Current session still valid")
      let returnVal = new Subject<boolean>()
      this.updateActiveUser().subscribe({
        next: (val) => {
          return returnVal.next(val)
        },
        error: (err) => {
          console.error(err)
          this.loginWithToken().subscribe({
            next: (val) => {
              returnVal.next(val)
            },
            error: (err2) => {
              console.error(err2)
              returnVal.next(false)
            },
          })
        },
      })
      return returnVal
    } else if (this.isLoginTokenValid()) {
      console.debug("[AuthService] Login token still valid")
      return this.loginWithToken()
    }
    return of(false)
  }

  isLoggedIn() {
    return this.activeUser.getValue() !== undefined
  }

  isLoggedOut() {
    return !this.isLoggedIn()
  }

  isCurrentSessionValid(): boolean {
    return this.sessionToken !== "" && !this.isExpired(this.sessionExpiry)
  }

  clearCurrentSession() {
    this.sessionToken = ""
    this.sessionExpiry = ""
  }

  clearStorage() {
    this.clearCurrentSession()
    this.loginToken = ""
    this.loginExpiry = ""
  }

  isLoginTokenValid(): boolean {
    return this.loginToken !== "" && !this.isExpired(this.loginExpiry)
  }

  popLoginRedirect(): string {
    const redirect = this.loginRedirect ?? environment.defaultPath
    this.loginRedirect = undefined
    return redirect
  }

  /**
   * Update the active user object using the access token.
   * @returns Whether or not the active user was successfully updated.
   */
  public updateActiveUser(): Observable<boolean> {
    // eslint-disable-next-line no-extra-parens
    return this.tokenUserEndpoint.get().pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === ErrorStatus.unAuthenticated) {
          console.debug(
            `[AuthService] ${error.error.messages[0].token_class} is invalid or expired.`,
          )
        }
        return throwError(() => error)
      }),
      tap<any>((user: User) => this.activeUser.next(user)),
      map<User, boolean>((user) => user !== undefined),
    )
  }

  /**
   * Refresh the tokens using the login token.
   * @returns Whether or not the login token was successfully refreshed.
   */
  private loginWithToken(): Observable<boolean> {
    if (this.loginToken == "" || !this.isLoginTokenValid() === null) {
      console.error("[AuthService] Cannot use login token: token is invalid!")
      return of(false)
    }
    const data = new TokenLoginData().deserialise({ token: this.loginToken })
    return this.loginEndpoint.post(data, { url: { token: true } }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error("[AuthService] Error while using login token: ", error)
        return throwError(() => error)
      }),
      tap<TokenData>((token) => this.storeSession(token)),
      map<TokenData, User>((token) => token.user),
      tap<User>((user) => this.activeUser.next(user)),
      map<User, boolean>((user) => user !== undefined),
    )
  }

  private isExpired(date: string): boolean {
    if (!date || date.trim().length === 0) {
      return true
    }
    return new Date() > new Date(date)
  }

  private storeSession(tokens: TokenData) {
    this.sessionToken = tokens.token
    this.sessionExpiry = tokens.tokenExpiry
    this.loginToken = tokens.loginToken
    this.loginExpiry = tokens.loginTokenExpiry
  }
}
