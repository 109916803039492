<app-banner>
  <div class="flex w-full flex-col items-center justify-center rounded-lg bg-white shadow-md">
    <div *ngIf="!resettingPassword" class="w-full p-10">
      <h1 class="text-primary" i18n="logIn.title">Inloggen</h1>
      <form class="space-y-4 md:space-y-6">
        <div class="input-container">
          <label for="loginEmailInput" class="mb-2 block" i18n="@@email">E-mailadres</label>
          <input
            type="email"
            name="loginEmailInput"
            id="loginEmailInput"
            class="block w-full rounded-lg border bg-background p-2.5"
            required
            [(ngModel)]="email"
          />
        </div>
        <div class="input-container">
          <label for="password" class="mb-2 block" i18n="@@password">Wachtwoord</label>
          <input
            type="password"
            name="password"
            id="password"
            class="block w-full rounded-lg border bg-background p-2.5"
            required
            [(ngModel)]="password"
          />
        </div>
        <button
          type="submit"
          class="button-primary w-full"
          i18n="@@logIn"
          (click)="login()"
          (keypress)="login()"
          >Log in</button
        >
        <div
          class="flex items-center justify-center"
          (click)="resettingPassword = true"
          (keypress)="resettingPassword = true"
        >
          <a i18n="@@forgotPassword">Wachtwoord vergeten?</a>
        </div>
      </form>
    </div>
    <div *ngIf="resettingPassword" class="w-full p-10">
      <h1 class="text-primary" i18n="forgotPassword">Wachtwoord vergeten</h1>
      <form class="space-y-4 md:space-y-6">
        <div class="input-container">
          <label for="resetEmailInput" class="mb-2 block" i18n="@@email">E-mailadres</label>
          <input
            type="email"
            name="resetEmailInput"
            id="resetEmailInput"
            class="block w-full rounded-lg border bg-background p-2.5"
            required
            [(ngModel)]="email"
          />
        </div>
        <button
          type="submit"
          class="button-primary w-full"
          i18n="@@resetPassword"
          (click)="resetPassword()"
          (keypress)="resetPassword()"
        >
          Wachtwoord opnieuw instellen
        </button>
      </form>
    </div>
  </div>
</app-banner>
