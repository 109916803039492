import { Deserialise } from "./deserialise.decorator"
import { Serialise } from "./serialise.decorator"

/**
 * Mark a model property as both serialisable and deserialisable.
 * Properties with this decorator will be converted automatically when calling serialise() or deserialise().
 * The decorator will automatically try to convert the bound property's name to a snake_case name and vice-versa.
 *
 * @param key [Optional] Key that will be used instead of the automatically generated one.
 **/
export function Field(key?: string) {
  return (target: any, bindingPropertyName?: string): any => {
    Serialise(key)(target, bindingPropertyName)
    Deserialise(key)(target, bindingPropertyName)
  }
}
