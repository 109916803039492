import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { ShiftType } from "app/planner/models"
import { PIEndpoint, PIEndpointBuilder } from "@shared/utils/endpoint-builder"
import { PlanningService } from "./planning.service"
import { UnitService } from "./unit.service"
import { environment } from "@env"

@Injectable({
  providedIn: "root",
})
export class ShiftService {
  shiftTypeEndpoint: PIEndpoint<ShiftType>

  constructor(
    private endPointBuilder: PIEndpointBuilder,
    private unitService: UnitService,
    private planningService: PlanningService,
  ) {
    this.shiftTypeEndpoint = this.endPointBuilder.initialise(
      ShiftType,
      environment.api,
      (data: { shiftTypeId: number }) => {
        if (data?.shiftTypeId) {
          return `/units/${this.getCurrentUnitId()}/plannings/${this.getCurrentPlanningId()}/shift-types/${
            data.shiftTypeId
          }`
        } else {
          return `/units/${this.getCurrentUnitId()}/plannings/${this.getCurrentPlanningId()}/shift-types/`
        }
      },
    )
  }

  private getCurrentUnitId(): number {
    return this.unitService.getCurrentUnitId()
  }

  private getCurrentPlanningId(): number {
    return this.planningService.getCurrentPlanningId()
  }

  getAllUnitPlanningShiftTypes = () => this.shiftTypeEndpoint.get() as Observable<ShiftType[]>
  getUnitPlanningShiftType = (shiftTypeId: number) =>
    this.shiftTypeEndpoint.get({ url: { shiftTypeId: shiftTypeId } })
  createUnitPlanningShiftType = (shiftType: ShiftType) => this.shiftTypeEndpoint.post(shiftType)
  updateUnitPlanningShiftType = (shiftTypeId: number, shiftType: ShiftType) =>
    this.shiftTypeEndpoint.patch(shiftType, { url: { shiftTypeId: shiftTypeId } })
  deleteUnitPlanningShiftType = (shiftTypeId: number) =>
    this.shiftTypeEndpoint.delete({ url: { shiftTypeId: shiftTypeId } })
}
