import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { Observable } from "rxjs"
import { AuthService } from "@core/services"

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * Intercept HTTP requests, set JWT in the request headers, and redirect on 401 UNAUTHORIZED errors.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getSessionToken()
    let modReq = req
    let usedAuthentication = false

    if (token) {
      usedAuthentication = true
      modReq = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + token),
      })
    }

    // Handle the modified request, and check for 401 errors.
    return next.handle(modReq)
  }
}
