import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Tag, TagType } from "app/planner/models"
import { PlanningService } from "@core/services/planning.service"
import { UnitService } from "@core/services/unit.service"
import { PIEndpoint, PIEndpointBuilder } from "@shared/utils/endpoint-builder"
import { environment } from "@env"

@Injectable({
  providedIn: "root",
})
export class TagService {
  tagTypeEndpoint: PIEndpoint<TagType>
  tagEndpoint: PIEndpoint<Tag>

  constructor(
    private endPointBuilder: PIEndpointBuilder,
    private unitService: UnitService,
    private planningService: PlanningService,
  ) {
    this.tagTypeEndpoint = this.endPointBuilder.initialise(
      TagType,
      environment.api,
      (data: { id: number }) => {
        if (data?.id) {
          return `/tag-types/${data.id}`
        } else {
          return `/tag-types/`
        }
      },
    )
    this.tagEndpoint = this.endPointBuilder.initialise(
      Tag,
      environment.api,
      (data: { id: number }) => {
        if (data?.id) {
          return `/units/${this.unitService.getCurrentUnitId()}/plannings/${this.planningService.getCurrentPlanningId()}/tags/${
            data.id
          }`
        } else {
          return `/units/${this.unitService.getCurrentUnitId()}/plannings/${this.planningService.getCurrentPlanningId()}/tags/`
        }
      },
    )
  }

  // Tag Types
  getAllTagTypes = () => this.tagTypeEndpoint.get() as Observable<TagType[]>
  getTagType = (tagTypeId: number) =>
    this.tagTypeEndpoint.get({ url: { id: tagTypeId } }) as Observable<TagType>
  updateTagType = (tagTypeId: number, tagType: TagType) =>
    this.tagTypeEndpoint.patch(tagType, { url: { id: tagTypeId } })
  deleteTagType = (tagTypeId: number) => this.tagTypeEndpoint.delete({ url: { id: tagTypeId } })

  // (Unit-Planning) Tags
  getAllUnitPlanningTags = () => this.tagEndpoint.get() as Observable<Tag[]>
  getUnitPlanningTag = (tagId: number) =>
    this.tagEndpoint.get({ url: { id: tagId } }) as Observable<Tag>
  createUnitPlanningTag = (tag: Tag) => this.tagEndpoint.post(tag)
  updateUnitPlanningTag = (tag: Tag) => this.tagEndpoint.patch(tag, { url: { id: tag.id } })
  deleteUnitPlanningTag = (tagId: number) => this.tagEndpoint.delete({ url: { id: tagId } })
}
