<div
  class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center overflow-auto bg-background"
  [class.hidden]="(appService.loading | async) === false"
>
  <app-loading></app-loading>
</div>

<div [class.hidden]="(appService.loading | async) === true">
  <main class="absolute bottom-0 left-0 right-0 top-0 overflow-auto bg-background">
    <router-outlet></router-outlet>
  </main>
</div>

<p-toast position="bottom-center"></p-toast>
