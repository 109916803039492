import { NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { InputTextModule } from "primeng/inputtext"
import { SharedModule } from "@shared/shared.module"
import { ForbiddenComponent } from "./forbidden/forbidden.component"
import { LandingRedirectComponent } from "./landing-redirect/landing-redirect.component"
import { LogoutComponent } from "./logout/logout.component"
import { NotFoundComponent } from "./not-found/not-found.component"
import { PasswordResetConfirmComponent } from "./password-reset-confirm/password-reset-confirm.component"
import { PublicRoutingModule } from "./public-routing.module"
import { UnauthenticatedComponent } from "./unauthenticated/unauthenticated.component"

@NgModule({
  declarations: [
    ForbiddenComponent,
    LandingRedirectComponent,
    NotFoundComponent,
    PasswordResetConfirmComponent,
    UnauthenticatedComponent,
    LogoutComponent,
  ],
  imports: [SharedModule, PublicRoutingModule, FormsModule, InputTextModule],
  exports: [],
})
export class PublicModule {}
