import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { AppService, AuthService } from "@core/services"
import { ErrorStatus } from "@public/models"

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    public appService: AppService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // If the application loads for the first time, always try to refresh the user object
    this.tryAutoLogin()
  }

  private tryAutoLogin(): void {
    this.appService.loading.next(true)
    this.authService.tryAutoLogin(true).subscribe({
      next: (val) => {
        if (val === false) {
          this.authService.logout().subscribe()
        }
        this.appService.loading.next(false)
      },
      error: (err) => {
        if (this.authService.isCurrentSessionValid() && err.status == ErrorStatus.unAuthenticated) {
          // The current session is clearly not valid, so try to refresh it
          this.authService.clearCurrentSession()
          this.tryAutoLogin()
        } else {
          this.authService.logout().subscribe()
          this.appService.loading.next(false)
          if (err.status == ErrorStatus.unAuthenticated) {
            // @TODO: Check whether we actually need authentication for the requested url or not
            this.router.navigate(["/401"], { queryParams: { url: this.router.url } })
          }
        }
      },
    })
  }
}
