import { Deserialise, Field } from "@shared/decorators"
import { PIModel } from "@shared/models"

export class Tag extends PIModel {
  @Field() name = ""
  @Deserialise() tagTypeName = "<unknown tagType>"
  @Field() tagTypeId = 0

  static override Sort(t1: Tag, t2: Tag): number {
    if (t1.tagTypeId === t2.tagTypeId) {
      return t1.name.localeCompare(t2.name) <= 0 ? -1 : 1
    } else {
      return t1.tagTypeName.localeCompare(t2.tagTypeName) <= 0 ? -1 : 1
    }
  }

  static override Copy(tag: any): any {
    let result = PIModel.Copy(tag)
    result.tagTypeName = tag.tagTypeName
    return result as Tag
  }
}
