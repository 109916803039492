import { ConstraintTypeEnum } from "@planner/models/constraint-type.enum"
import { PeriodPreference } from "@planner/models/period-preference.model"
import { Field } from "@shared/decorators"
import { PIModel } from "@shared/models"
import { formatDate } from "@shared/utils/format-date"

export type Constraint =
  | ExcludeConstraint
  | EqualPeriodOccurrenceConstraint
  | ImpliedExcludeConstraint
  | ImpliedExcludePeriodsConstraint
  | ImpliedIncludeConstraint
  | ImpliedIncludePeriodsConstraint
  | MaxUsersPerDayConstraint
  | MaxPerYearConstraint
  | MaxPerMonthConstraint
  | MaxPerWeekConstraint
  | MinPerYearConstraint
  | MinPerMonthConstraint
  | MinPerWeekConstraint
  | ShiftSeriesConstraint
  | RecoveryConstraint
  | PeriodPreferenceConstraint
  | LinkedConstraint
  | TagAvailabilityConstraint
  | HardcodedAssignmentConstraint
  | UnknownConstraint

export function constraintTypeToConstraintClass(type: ConstraintTypeEnum): Constraint {
  switch (type) {
    case "EXCLUDE":
      return new ExcludeConstraint()
    case "EQUAL_PERIOD_OCCURRENCE":
      return new EqualPeriodOccurrenceConstraint()
    case "IMPLIED_EXCLUDE":
      return new ImpliedExcludeConstraint()
    case "IMPLIED_EXCLUDE_PERIODS":
      return new ImpliedExcludePeriodsConstraint()
    case "IMPLIED_INCLUDE":
      return new ImpliedIncludeConstraint()
    case "IMPLIED_INCLUDE_PERIODS":
      return new ImpliedIncludePeriodsConstraint()
    case "LINKED":
      return new LinkedConstraint()
    case "MAX_PER_MONTH":
      return new MaxPerMonthConstraint()
    case "MAX_PER_WEEK":
      return new MaxPerWeekConstraint()
    case "MAX_PER_YEAR":
      return new MaxPerYearConstraint()
    case "MAX_USERS_PER_DAY":
      return new MaxUsersPerDayConstraint()
    case "MIN_PER_MONTH":
      return new MinPerMonthConstraint()
    case "MIN_PER_WEEK":
      return new MinPerWeekConstraint()
    case "MIN_PER_YEAR":
      return new MinPerYearConstraint()
    case "PERIOD_PREFERENCE":
      return new PeriodPreferenceConstraint()
    case "RECOVERY":
      return new RecoveryConstraint()
    case "SHIFT_SERIES":
      return new ShiftSeriesConstraint()
    case "TAG_AVAILABILITY":
      return new TagAvailabilityConstraint()
    case "HARDCODED_ASSIGNMENT":
      return new HardcodedAssignmentConstraint()
    case "UNKNOWN":
      return new UnknownConstraint()
  }
  return new UnknownConstraint()
}

export class ConstraintBase extends PIModel {
  @Field() constraintType?: number
  @Field() hard = true
  @Field() isActive = true
  @Field() planning?: number
  @Field() profile?: number
  @Field() isProfileConstraint = false

  sort(b: ConstraintBase): number {
    return ConstraintBase.Sort(this, b)
  }

  /*
  Takes 4 arguments, and will sort on the first 2 if they are defined and not the same,
   otherwise the last 2 arguments are used.
   */
  protected sortInOrder<A extends Date | number | undefined, B extends Date | number | undefined>(
    a: A,
    b: A,
    x: B,
    y: B,
  ): number {
    if (a !== undefined && b !== undefined && a !== b) {
      return a > b ? -1 : 1
    }
    if (x  !== undefined && y !== undefined && x !== y) {
      return x > y ? -1 : 1
    }
    return 0
  }

  /*
    Takes a list of constraints of various types, sorts them:
     - by type in groups
     - per group, the constraints themselves

    If sortGroupsByLength (default false) == true, also sorts
    - those groups in oder of ascending length
  */
  static sortMixedConstraints(constraints: Constraint[], sortGroupsByLength = false): Constraint[] {
    // group constraints by type
    const groupedConstraints: { [constraintType: number]: Constraint[] } = {}
    // save the order of constraintTypes, the first one is possibly newly added so save it separately
    const constraintOrder: number[] = Array.from(
      new Set(constraints.slice(1).map((constraint) => constraint.constraintType || -1)),
    )
    const firstOne = constraints[0].constraintType || -1

    // if there are no other constraints of the same type as the first constraint, add it in front
    if (firstOne !== null && !constraintOrder.includes(firstOne)) {
      constraintOrder.unshift(firstOne)
    }

    // make groups of the constraints by type
    constraints.forEach((constraint) => {
      const cType = constraint.constraintType || -1
      if (!groupedConstraints[cType]) {
        groupedConstraints[cType] = []
      }
      groupedConstraints[cType].push(constraint)
    })

    // sort each constraintType separately
    for (const subtype in groupedConstraints) {
      groupedConstraints[subtype].sort((a: ConstraintBase, b: ConstraintBase) => {
        return a.sort(b)
      })
    }

    // make list of all the groups, in the original order
    const sortedConstraints: Constraint[][] = []
    constraintOrder.forEach((type) => {
      sortedConstraints.push(groupedConstraints[type])
    })
    if (sortGroupsByLength) {
      // sort the groups based on length
      sortedConstraints.sort((a, b) => {
        return a.length - b.length
      })
    }
    return sortedConstraints.flat()
  }
}

export class ExcludeConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.Exclude as const

  @Field() startDate?: Date
  @Field() endDate?: Date

  override sort(b: ExcludeConstraint): number {
    return this.sortInOrder(this.startDate, b.startDate, this.endDate, b.endDate)
  }

  override serialise(): Record<string, any> {
    let data = super.serialise()

    if (this.startDate !== undefined) {
      const startDate = new Date(this.startDate)
      data["start_date"] = formatDate(startDate)
    } else {
      data["start_date"] = null
    }

    if (this.endDate !== undefined) {
      const endDate = new Date(this.endDate)
      data["end_date"] = formatDate(endDate)
    } else {
      data["end_date"] = null
    }

    return data
  }

  override deserialise(data: any): this {
    super.deserialise(data)
    this.startDate = data.start_date ? new Date(data.start_date) : this.startDate
    this.endDate = data.end_date ? new Date(data.end_date) : this.endDate

    return this
  }
}

export class ImpliedExcludeConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.ImpliedExclude as const

  @Field() date1?: Date
  @Field() date2?: Date
  @Field() shiftType1Id?: number
  @Field() shiftType1Name?: string
  @Field() shiftType2Id?: number
  @Field() shiftType2Name?: string

  override sort(b: ImpliedExcludeConstraint): number {
    return this.sortInOrder(this.date1, b.date1, this.date2, b.date2)
  }

  override serialise(): Record<string, any> {
    let data = super.serialise()

    if (this.date1 !== undefined) {
      const date1 = new Date(this.date1)
      data["date1"] = formatDate(date1)
    } else {
      data["date1"] = null
    }

    if (this.date2 !== undefined) {
      const date2 = new Date(this.date2)
      data["date2"] = formatDate(date2)
    } else {
      data["date2"] = null
    }

    return data
  }

  override deserialise(data: any): this {
    super.deserialise(data)
    this.date1 = data.date1 ? new Date(data.date1) : this.date1
    this.date2 = data.date2 ? new Date(data.date2) : this.date2

    return this
  }
}

export class ImpliedExcludePeriodsConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.ImpliedExcludePeriods as const

  @Field() periodId1?: number
  @Field() periodId2?: number
  @Field() periodId3?: number

  override sort(b: ImpliedExcludePeriodsConstraint): number {
    return this.sortInOrder(this.periodId1, b.periodId1, this.periodId2, b.periodId2)
  }
}

export class ImpliedIncludeConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.ImpliedInclude as const

  @Field() date1?: Date
  @Field() date2?: Date
  @Field() shiftType1Id?: number
  @Field() shiftType1Name?: string
  @Field() shiftType2Id?: number
  @Field() shiftType2Name?: string

  override sort(b: ImpliedIncludeConstraint): number {
    return this.sortInOrder(this.date1, b.date1, this.date2, b.date2)
  }

  override serialise(): Record<string, any> {
    let data = super.serialise()

    if (this.date1 !== undefined) {
      const date1 = new Date(this.date1)
      data["date1"] = formatDate(date1)
    } else {
      data["date1"] = null
    }

    if (this.date2 !== undefined) {
      const date2 = new Date(this.date2)
      data["date2"] = formatDate(date2)
    } else {
      data["date2"] = null
    }

    return data
  }

  override deserialise(data: any): this {
    super.deserialise(data)
    this.date1 = data.date1 ? new Date(data.date1) : this.date1
    this.date2 = data.date2 ? new Date(data.date2) : this.date2

    return this
  }
}

export class ImpliedIncludePeriodsConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.ImpliedIncludePeriods as const

  @Field() periodId1?: number
  @Field() periodId2?: number

  override sort(b: ImpliedIncludePeriodsConstraint): number {
    return this.sortInOrder(this.periodId1, b.periodId1, this.periodId2, b.periodId2)
  }
}

export class MaxUsersPerDayConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.MaxUsersPerDay as const

  @Field() maximum = 0

  override sort(b: MaxUsersPerDayConstraint): number {
    return this.maximum - b.maximum
  }
}

export class PeriodPreferenceConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.PeriodPreference as const

  @Field() constraintPeriodId?: number
  @Field() periodPreferences = [] as PeriodPreference[]

  override sort(b: PeriodPreferenceConstraint): number {
    if (this.constraintPeriodId && b.constraintPeriodId) {
      return this.constraintPeriodId - b.constraintPeriodId
    }
    return 0
  }

  override deserialise(data: Record<string, any>): this {
    super.deserialise(data)

    if (data["period_preferences"] !== undefined) {
      this.periodPreferences = data["period_preferences"].map((preference: Record<string, any>) =>
        new PeriodPreference().deserialise(preference),
      )
    }

    return this
  }

  override serialise(): Record<string, any> {
    let data = super.serialise()
    data["period_preferences"] = this.periodPreferences.map((preference) => preference.serialise())
    return data
  }
}

export class RecoveryConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.Recovery as const

  @Field() numberOfRecoveryDays = 0
  @Field() numberOfConsecutiveAssignments = 0
  @Field() shiftTypeId?: number
  @Field() shiftTypeName?: string
  @Field() includeWeekends = true

  override sort(b: RecoveryConstraint): number {
    return this.sortInOrder(
      this.shiftTypeId,
      b.shiftTypeId,
      this.numberOfRecoveryDays,
      b.numberOfRecoveryDays,
    )
  }
}

export class ShiftSeriesConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.ShiftSeries as const

  @Field() shiftTypeId?: number
  @Field() shiftTypeName?: string
  @Field() dayOfWeek = 0
  @Field() seriesLength = 0
  @Field() numberOfRecoveryDays = 0
  @Field() excludeOnlyDifferentTypes = false

  override sort(b: ShiftSeriesConstraint): number {
    return this.sortInOrder(this.shiftTypeId, b.shiftTypeId, this.dayOfWeek, b.dayOfWeek)
  }
}

export class LinkedConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.Linked as const

  @Field() periodId1?: number
  @Field() periodId2?: number

  override sort(b: LinkedConstraint): number {
    return this.sortInOrder(this.periodId1, b.periodId1, this.periodId2, b.periodId2)
  }
}

export class MaxPerYearConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.MaxPerYear as const

  @Field() maximum = 0
  @Field() shiftTypeId?: number
  @Field() shiftTypeName?: string

  override sort(b: MaxPerYearConstraint): number {
    if (this.shiftTypeId && b.shiftTypeId) return this.shiftTypeId - b.shiftTypeId
    return 0
  }
}

export class MaxPerMonthConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.MaxPerMonth as const

  @Field() maximum = 0
  @Field() shiftTypeId?: number
  @Field() shiftTypeName?: string

  override sort(b: MaxPerMonthConstraint): number {
    if (this.shiftTypeId && b.shiftTypeId) return this.shiftTypeId - b.shiftTypeId
    return 0
  }
}

export class MaxPerWeekConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.MaxPerWeek as const

  @Field() maximum = 0
  @Field() shiftTypeId?: number
  @Field() shiftTypeName?: string

  override sort(b: MaxPerWeekConstraint): number {
    if (this.shiftTypeId && b.shiftTypeId) return this.shiftTypeId - b.shiftTypeId
    return 0
  }
}

export class MinPerYearConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.MinPerYear as const

  @Field() minimum = 0
  @Field() shiftTypeId?: number
  @Field() shiftTypeName?: string

  override sort(b: MinPerYearConstraint): number {
    if (this.shiftTypeId && b.shiftTypeId) return this.shiftTypeId - b.shiftTypeId
    return 0
  }
}

export class MinPerMonthConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.MinPerMonth as const

  @Field() minimum = 0
  @Field() shiftTypeId?: number
  @Field() shiftTypeName?: string

  override sort(b: MinPerMonthConstraint): number {
    if (this.shiftTypeId && b.shiftTypeId) return this.shiftTypeId - b.shiftTypeId
    return 0
  }
}

export class MinPerWeekConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.MinPerWeek as const

  @Field() minimum = 0
  @Field() shiftTypeId?: number
  @Field() shiftTypeName?: string

  override sort(b: MinPerWeekConstraint): number {
    if (this.shiftTypeId && b.shiftTypeId) return this.shiftTypeId - b.shiftTypeId
    return 0
  }
}

export class TagAvailabilityConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.TagAvailability as const

  @Field() tagId = 0
  @Field() tagName = ""
  @Field() availability = 0

  override sort(b: TagAvailabilityConstraint): number {
    return this.tagName.localeCompare(b.tagName)
  }
}

export class HardcodedAssignmentConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.HardcodedAssignment as const

  @Field() userId = 0
  @Field() userFullName?: string
  @Field() date?: Date
  @Field() shiftTypeId = 0
  @Field() shiftTypeName?: string

  override serialise(): Record<string, any> {
    let data = super.serialise()
    const datelen = 10
    if (this.date !== undefined) {
      data["date"] = new Date(this.date).toISOString().slice(0, datelen)
    }

    return data
  }

  override deserialise(data: any): this {
    super.deserialise(data)
    this.date = data.date ? new Date(data.date) : this.date

    return this
  }

  override sort(b: HardcodedAssignmentConstraint): number {
    return this.sortInOrder(this.date, b.date, this.shiftTypeId, b.shiftTypeId)
  }
}

export class EqualPeriodOccurrenceConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.EqualPeriodOccurrence as const

  @Field() periodId1?: number
  @Field() periodId2?: number

  override sort(b: EqualPeriodOccurrenceConstraint): number {
    return this.sortInOrder(this.periodId1, b.periodId1, this.periodId2, b.periodId2)
  }
}

export class UnknownConstraint extends ConstraintBase {
  constraintTypeEnum = ConstraintTypeEnum.Unknown as const
}
