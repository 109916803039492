/**
 * Import extra locale data (mainly used for pipes and directives)
 */
import { registerLocaleData } from "@angular/common"
import en from "@angular/common/locales/en"
import fr from "@angular/common/locales/fr"
import nl from "@angular/common/locales/nl"
import { LOCALE_ID, NgModule } from "@angular/core"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { ServiceWorkerModule } from "@angular/service-worker"
import { FaIconLibrary } from "@fortawesome/angular-fontawesome"
import {
  faEdit,
  faEye,
  faPenToSquare,
  faShareFromSquare,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons"
import {
  faArrowLeft,
  faCheck,
  faCircleExclamation,
  faCircleNotch,
  faCircleQuestion,
  faPlus,
  faX,
} from "@fortawesome/free-solid-svg-icons"
import { ToastModule } from "primeng/toast"
import * as CoreComponents from "@core/components"
import { CoreModule } from "@core/core.module"
import { AppService } from "@core/services/app.service"
import { SharedModule } from "@shared/shared.module"
import { PublicModule } from "@public/public.module"
import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { environment } from "@env"

@NgModule({
  declarations: [AppComponent, ...CoreComponents.components],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    CoreModule.forRoot(),
    PublicModule,
    SharedModule,
    ToastModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [AppService],
      useFactory: (service: AppService) => service.getCurrentLocale(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(
      faCircleExclamation,
      faCircleQuestion,
      faCheck,
      faCircleNotch,
      faEye,
      faEdit,
      faArrowLeft,
      faPenToSquare,
      faPlus,
      faTrashCan,
      faShareFromSquare,
      faX,
    )
  }
}

registerLocaleData(nl, "nl")
registerLocaleData(fr, "fr")
registerLocaleData(en, "en")
