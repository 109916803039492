import { Component, OnInit } from "@angular/core"
import { $localize } from "@angular/localize/init"
import { filter } from "rxjs"
import { AppService, AuthService } from "@core/services"
import { NotificationService } from "@core/services/notification.service"

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
  loading = true
  error = false

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    // No logout if we are not logged in
    this.appService.loading.pipe(filter((val) => !val)).subscribe(() => {
      if (this.authService.isLoggedOut()) {
        this.loading = false
        return
      }
      this.authService.logout().subscribe({
        next: () => (this.loading = false),
        error: (err) => {
          this.error = true
          this.loading = false
          this.notificationService.showErrorMessage(
            $localize`:@@logout.error:Er ging iets fout tijdens het uitloggen. Probeer later opnieuw.`,
          )
          console.error("error while logging out", err)
        },
      })
    })
  }
}
