import { CommonModule } from "@angular/common"
import { ModuleWithProviders, NgModule } from "@angular/core"
import { MessageService } from "primeng/api"
import { HttpInterceptorProviders } from "./interceptors"

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [HttpInterceptorProviders, MessageService],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return { ngModule: CoreModule }
  }
}
