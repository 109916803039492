import { Field } from "@shared/decorators"
import { PIModel } from "@shared/models"

export class PeriodPreference extends PIModel {
  @Field() order = 1
  @Field() periodId?: number

  static override Sort(pp1: PeriodPreference, pp2: PeriodPreference): number {
    return pp1.order - pp2.order
  }
}
