export const environment = {
  production: false,

  version: "1.0.0",

  // Tailwind screen sizes (in px)
  smScreenWidth: 640,
  mdScreenWidth: 768,
  lgScreenWidth: 1024,
  xlScreenWidth: 1280,
  twoXlScreenWidth: 1536,

  // The api endpoints
  api: undefined,
  locales: ["nl", "en", "fr"],
  defaultLocale: "nl", // Fallback locale to use when no supported locale could be detected
  storageKeys: {
    LOCALE: "locale",
  },
  applicationName: "Wachtplanner",

  // The path users will be redirected to on login
  defaultLoginPath: "/login", //If not logged in yet
  defaultPath: "/units", //If logged in

  tokens: {
    loginToken: "argos.loginToken",
    loginExpiry: "argos.loginExpiry",
    sessionToken: "argos.sessionToken",
    sessionExpiry: "argos.sessionExpiry",
  },
}
