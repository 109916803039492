import { Component, Input } from "@angular/core"
import { Router } from "@angular/router"
import { faX } from "@fortawesome/free-solid-svg-icons"
import { AuthService } from "@core/services"
import { NotificationService } from "@core/services/notification.service"
import { LoginData, PasswordResetData } from "@public/models"

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  @Input() hideReturn = true
  @Input() redirect = true

  email = ""
  password = ""

  resettingPassword = false

  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private router: Router,
  ) {}

  login(): void {
    const loginData = new LoginData().deserialise({ email: this.email, password: this.password })
    this.authService.login(loginData).subscribe({
      next: (_) => {
        if (this.redirect) {
          const url = this.authService.popLoginRedirect()
          this.router.navigate([url])
        }
      },
      error: (err) => {
        console.error("error while logging in", err)
        this.notificationService.showErrorMessage(
          $localize`:@@login.error:Er ging iets fout tijdens het inloggen met bovenstaande gegevens. Probeer later opnieuw.`,
        )
      },
    })
  }

  resetPassword(): void {
    const passwordResetData = new PasswordResetData().deserialise({ email: this.email })
    this.authService.resetPassword(passwordResetData).subscribe({
      next: (_) => {
        this.resettingPassword = false
        this.notificationService.showSuccessMessage(
          $localize`:@@passwordReset.success:De aanvraag tot herstel van het wachtwoord is succesvol verzonden. Je zal een mail ontvangen met een tijdelijke link om een nieuw wachtwoord in te stellen.`,
        )
      },
      error: (err) => {
        this.resettingPassword = false
        this.notificationService.showErrorMessage(
          $localize`:@@passwordReset.error:Er ging iets fout tijdens het aanvragen van een nieuw wachtwoord. Probeer later opnieuw.`,
        )
        console.error("error while resetting password", err)
      },
    })
  }
}
