import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Period } from "app/planner/models"
import { PlanningService, UnitService } from "@core/services"
import { PIEndpoint, PIEndpointBuilder } from "@shared/utils/endpoint-builder"
import { environment } from "@env"

@Injectable({
  providedIn: "root",
})
export class PeriodService {
  periodsEndpoint: PIEndpoint<Period>

  constructor(
    private endPointBuilder: PIEndpointBuilder,
    private unitService: UnitService,
    private planningService: PlanningService,
  ) {
    this.periodsEndpoint = this.endPointBuilder.initialise(
      Period,
      environment.api,
      (data: { unitId: number; planningId: number; id: number }) => {
        if (data?.id) {
          return `/units/${unitService.getCurrentUnitId()}/plannings/${planningService.getCurrentPlanningId()}/periods/${
            data.id
          }`
        } else {
          return `/units/${unitService.getCurrentUnitId()}/plannings/${planningService.getCurrentPlanningId()}/periods/`
        }
      },
    )
  }

  getAllUnitPlanningPeriods = () => this.periodsEndpoint.get() as Observable<Period[]>
  getUnitPlanningPeriod = (periodId: number) => this.periodsEndpoint.get({ url: { id: periodId } })
  createUnitPlanningPeriod = (period: Period) => this.periodsEndpoint.post(period)
  updateUnitPlanningPeriod = (period: Period) =>
    this.periodsEndpoint.patch(period, { url: { id: period.id } })
  deleteUnitPlanningPeriod = (periodId: number) =>
    this.periodsEndpoint.delete({ url: { id: periodId } })
}
