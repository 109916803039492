<div class="container">
  <div class="grid grid-cols-12 gap-0 pt-5">
    <div class="col-span-6 col-start-3">
      <div class="alert danger" role="alert">
        <h2 class="pl-2">
          <fa-icon icon="circle-exclamation" class="pr-2"></fa-icon>
          <span i18n="@@forbidden.title">Toegang geweigerd</span>
        </h2>
        <a routerLink="/" class="fluid center aligned" i18n="@@pageNotFound.returnToHome"
          >Klik hier om terug te keren naar de hoofdpagina</a
        >
      </div>
    </div>
  </div>
</div>
