/**
 * Automatically generate a snake_case key name for a camelCase property name.
 * The generated name can optionally be overwritten by defining a value for the overwrite parameter.
 *
 * @param name The property name
 * @param overwrite Optional value that overwrites the generated key
 * @returns The generated key or overwrite value
 **/
export function convertPropertyName(name: string, overwrite?: string): string {
  if (overwrite != undefined) {
    return overwrite
  } else {
    // Replace camelCase by snake_case using the following form: objectIDValue -> object_id_value
    var prop = name.replace(/[A-Z][a-z]/g, (letters) => `_${letters.toLowerCase()}`)
    prop = prop.replace(/[A-Z]+/g, (letters) => `_${letters.toLowerCase()}`)
    return prop
  }
}
