import { Deserialise, Field } from "@shared/decorators"
import { PIModel, User } from "@shared/models"

export class UnitUser extends PIModel {
  @Deserialise() user = new User()
  @Field() email = ""
  @Field() isPlanner = false

  static override Sort(uu1: UnitUser, uu2: UnitUser): number {
    return uu1.user.email.localeCompare(uu2.user.email) <= 0 ? -1 : 1
  }
}
