import * as SharedEnvironment from "./environment.shared"

export const environment = {
  ...SharedEnvironment.environment,

  production: true,

  // Add other environment overrides here

  api: `https://api.argos.prior-it.be/`,
}
