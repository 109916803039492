import { Field } from "@shared/decorators"
import { PIModel } from "@shared/models"

export class ShiftType extends PIModel {
  @Field() type = ""

  static override Sort(st1: ShiftType, st2: ShiftType): number {
    return st1.type.localeCompare(st2.type) <= 0 ? -1 : 1
  }
}
