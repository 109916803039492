import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { Constraint, ConstraintType, constraintTypeToConstraintClass } from "@planner/models"
import { PlanningService, UnitService } from "@core/services"
import { PIEndpoint, PIEndpointBuilder } from "@shared/utils/endpoint-builder"
import { environment } from "@env"

@Injectable({
  providedIn: "root",
})
export class ConstraintService {
  constraintTypeEndpoint: PIEndpoint<ConstraintType>

  constructor(
    private unitService: UnitService,
    private planningService: PlanningService,
    private http: HttpClient,
    private endPointBuilder: PIEndpointBuilder,
  ) {
    this.constraintTypeEndpoint = this.endPointBuilder.initialise(
      ConstraintType,
      environment.api,
      (data: { id: number }) => {
        if (data?.id) {
          return `/constraint-types/${data.id}/`
        } else {
          return `/constraint-types/`
        }
      },
    )
  }

  constraintEndpoint(id?: number): string {
    if (id === undefined) {
      return `${
        environment.api
      }units/${this.unitService.getCurrentUnitId()}/plannings/${this.planningService.getCurrentPlanningId()}/constraints/`
    } else {
      return `${
        environment.api
      }units/${this.unitService.getCurrentUnitId()}/plannings/${this.planningService.getCurrentPlanningId()}/constraints/${id.toString()}/`
    }
  }

  getAllConstraintTypes = () => this.constraintTypeEndpoint.get() as Observable<ConstraintType[]>
  getConstraintType = (ctId: number) => this.constraintTypeEndpoint.get({ url: { id: ctId } })

  getAllUnitConstraints(): Observable<Constraint[]> {
    return this.http.get<any[]>(this.constraintEndpoint()).pipe(
      map((constraints) =>
        constraints.map((constraint) => {
          return constraintTypeToConstraintClass(constraint.constraint_type_name).deserialise(
            constraint,
          )
        }),
      ),
    )
  }

  getAllProfileConstraints(profileId: number): Observable<Constraint[]> {
    return this.http.get<any[]>(this.constraintEndpoint(), { params: { profile: profileId } }).pipe(
      map((constraints) =>
        constraints.map((constraint) => {
          return constraintTypeToConstraintClass(constraint.constraint_type_name).deserialise(
            constraint,
          )
        }),
      ),
    )
  }

  getConstraint(id: number): Observable<Constraint> {
    return this.http.get<any>(this.constraintEndpoint(id)).pipe(
      map((constraint) => {
        return constraintTypeToConstraintClass(constraint.constraint_type_name).deserialise(
          constraint,
        )
      }),
    )
  }

  createConstraint<T extends Constraint>(newConstraint: T): Observable<T> {
    return this.http.post<T>(this.constraintEndpoint(), newConstraint.serialise()).pipe(
      map((createdConstraint: any) => {
        return constraintTypeToConstraintClass(createdConstraint.constraint_type_name).deserialise(
          createdConstraint,
        ) as T
      }),
    )
  }

  updateConstraint<T extends Constraint>(constraint: T): Observable<T> {
    console.debug(constraint.serialise())

    return this.http.patch<T>(this.constraintEndpoint(constraint.id), constraint.serialise()).pipe(
      map((updatedConstraint: any) => {
        return constraintTypeToConstraintClass(updatedConstraint.constraint_type_name).deserialise(
          updatedConstraint,
        ) as T
      }),
    )
  }

  deleteConstraint(id: number): Observable<void> {
    return this.http.delete<void>(this.constraintEndpoint(id))
  }
}
