import {
  EqualPeriodOccurrenceConstraint,
  ExcludeConstraint,
  ImpliedExcludeConstraint,
  ImpliedExcludePeriodsConstraint,
  ImpliedIncludeConstraint,
  ImpliedIncludePeriodsConstraint,
  LinkedConstraint,
  MaxPerMonthConstraint,
  MaxUsersPerDayConstraint,
  MinPerMonthConstraint,
  PeriodPreferenceConstraint,
  RecoveryConstraint,
  ShiftSeriesConstraint,
} from "@planner/models/constraint.model"

// eslint-disable-next-line no-shadow
export enum ConstraintTypeEnum {
  EqualPeriodOccurrence = "EQUAL_PERIOD_OCCURRENCE",
  Exclude = "EXCLUDE",
  ImpliedExclude = "IMPLIED_EXCLUDE",
  ImpliedExcludePeriods = "IMPLIED_EXCLUDE_PERIODS",
  ImpliedInclude = "IMPLIED_INCLUDE",
  ImpliedIncludePeriods = "IMPLIED_INCLUDE_PERIODS",
  Linked = "LINKED",
  MaxPerMonth = "MAX_PER_MONTH",
  MaxPerWeek = "MAX_PER_WEEK",
  MaxPerYear = "MAX_PER_YEAR",
  MaxUsersPerDay = "MAX_USERS_PER_DAY",
  MinPerMonth = "MIN_PER_MONTH",
  MinPerWeek = "MIN_PER_WEEK",
  MinPerYear = "MIN_PER_YEAR",
  PeriodPreference = "PERIOD_PREFERENCE",
  Recovery = "RECOVERY",
  ShiftSeries = "SHIFT_SERIES",
  TagAvailability = "TAG_AVAILABILITY",
  HardcodedAssignment = "HARDCODED_ASSIGNMENT",
  Unknown = "UNKNOWN",
}

export type FieldEntry = { name: string; required: boolean }
export type ConstraintTypeEntry = Record<"name" | "info", string> &
  Record<"fields", Partial<Record<any, FieldEntry>>>
export type ConstraintTypeMap = Record<ConstraintTypeEnum, ConstraintTypeEntry>
export const ConstraintTypeFields = {} as ConstraintTypeMap

ConstraintTypeFields[ConstraintTypeEnum.Exclude] = {
  name: $localize`:@@constraints.exclude.title:Niet beschikbaar`,
  info: $localize`:@@constraints.exclude.info:Tussen deze twee datums (inbegrepen) kan er geen shift toegekend worden`,
  fields: {
    startDate: {
      name: $localize`:@@constraints.exclude.date1:Startdatum`,
      required: true,
    },
    endDate: {
      name: $localize`:@@constraints.exclude.date2:Einddatum`,
      required: true,
    },
  } as Partial<Record<keyof ExcludeConstraint, FieldEntry>>,
}
ConstraintTypeFields[ConstraintTypeEnum.ImpliedExclude] = {
  name: $localize`:@@constraints.impliedExclude.title:Niet combineerbare datums`,
  info: $localize`:@@constraints.impliedExclude.info:Er kan ten hoogste op een van deze datums een shift toegekend worden van de gegeven types`,
  fields: {
    date1: {
      name: $localize`:@@constraints.implied.date1:Shiftdatum 1`,
      required: true,
    },
    date2: {
      name: $localize`:@@constraints.implied.date2:Shiftdatum 2`,
      required: true,
    },
    shiftType1Id: {
      name: $localize`:@@constraints.implied.shiftType1:Type shift 1`,
      required: false,
    },
    shiftType2Id: {
      name: $localize`:@@constraints.implied.shiftType2:Type shift 2`,
      required: false,
    },
  } as Partial<Record<keyof ImpliedExcludeConstraint, FieldEntry>>,
}
ConstraintTypeFields[ConstraintTypeEnum.ImpliedExcludePeriods] = {
  name: $localize`:@@constraints.impliedExcludePeriods.title:Niet combineerbare shifts`,
  info: $localize`:@@constraints.impliedExcludePeriods.info:Er kan ten hoogste een van deze shifts worden toegekend`,
  fields: {
    periodId1: {
      name: $localize`:@@constraints.impliedPeriods.float1:Shift 1`,
      required: true,
    },
    periodId2: {
      name: $localize`:@@constraints.impliedPeriods.float2:Shift 2`,
      required: true,
    },
    periodId3: {
      name: $localize`:@@constraints.impliedPeriods.float3:Shift 3`,
      required: false,
    },
  } as Partial<Record<keyof ImpliedExcludePeriodsConstraint, FieldEntry>>,
}
ConstraintTypeFields[ConstraintTypeEnum.ImpliedInclude] = {
  name: $localize`:@@constraints.impliedInclude.title:Gekoppelde datums`,
  info: $localize`:@@constraints.impliedInclude.info:Shift met de gegeven types kunnen alleen toegekend worden voor beide datums of voor geen van beide`,
  fields: {
    date1: {
      name: $localize`:@@constraints.implied.date1:Shiftdatum 1`,
      required: true,
    },
    date2: {
      name: $localize`:@@constraints.implied.date2:Shiftdatum 2`,
      required: true,
    },
    shiftType1Id: {
      name: $localize`:@@constraints.implied.shiftType1:Type shift 1`,
      required: false,
    },
    shiftType2Id: {
      name: $localize`:@@constraints.implied.shiftType2:Type shift 2`,
      required: false,
    },
  } as Partial<Record<keyof ImpliedIncludeConstraint, FieldEntry>>,
}
ConstraintTypeFields[ConstraintTypeEnum.ImpliedIncludePeriods] = {
  name: $localize`:@@constraints.impliedIncludePeriods.title:Gekoppelde shifts`,
  info: $localize`:@@constraints.impliedIncludePeriods.info:Een toekenning is alleen mogelijk voor beide shifts of voor geen van beide`,
  fields: {
    periodId1: {
      name: $localize`:@@constraints.impliedPeriods.float1:Shift 1`,
      required: true,
    },
    periodId2: {
      name: $localize`:@@constraints.impliedPeriods.float2:Shift 2`,
      required: true,
    },
  } as Partial<Record<keyof ImpliedIncludePeriodsConstraint, FieldEntry>>,
}
ConstraintTypeFields[ConstraintTypeEnum.Linked] = {
  name: $localize`:@@constraints.linked.title:Geïmpliceerde shift`,
  info: $localize`:@@constraints.linked.info:Indien shift 1 wordt toegekend geldt dit ook voor shift 2 in dezelfde week`,
  fields: {
    periodId1: {
      name: $localize`:@@constraints.impliedPeriods.float1:Shift 1`,
      required: true,
    },
    periodId2: {
      name: $localize`:@@constraints.impliedPeriods.float2:Shift 2`,
      required: true,
    },
  } as Partial<Record<keyof LinkedConstraint, FieldEntry>>,
}

ConstraintTypeFields[ConstraintTypeEnum.MaxPerMonth] = {
  name: $localize`:@@constraints.maxPerMonth.title:Maximum shifts per maand`,
  info: $localize`:@@constraints.maxPerMonth.info:Er kunnen slecht een maximaal aantal shifts van het gekozen type toegekend worden per maand`,
  fields: {
    maximum: {
      name: $localize`:@@constraints.maxShifts.float1:Maximum aantal shifts`,
      required: true,
    },
    shiftTypeId: {
      name: $localize`:@@constraints.max.shiftType1:Type shift`,
      required: false,
    },
  } as Partial<Record<keyof MaxPerMonthConstraint, FieldEntry>>,
}
ConstraintTypeFields[ConstraintTypeEnum.MaxPerWeek] = {
  name: $localize`:@@constraints.maxPerWeek.title:Maximum shifts per week`,
  info: $localize`:@@constraints.maxPerWeek.info:Er kunnen slecht een maximaal aantal shifts van het gekozen type toegekend worden per week`,
  fields: ConstraintTypeFields[ConstraintTypeEnum.MaxPerMonth].fields,
}
ConstraintTypeFields[ConstraintTypeEnum.MaxPerYear] = {
  name: $localize`:@@constraints.maxPerYear.title:Maximum shifts per planning`,
  info: $localize`:@@constraints.maxPerYear.info:Er kunnen slecht een maximaal aantal shifts van het gekozen type toegekend worden per planning`,
  fields: ConstraintTypeFields[ConstraintTypeEnum.MaxPerMonth].fields,
}
ConstraintTypeFields[ConstraintTypeEnum.MaxUsersPerDay] = {
  name: $localize`:@@constraints.maxUsers.title:Maximum aantal verschillende gebruikers per dag`,
  info: $localize`:@@constraints.maxUsers.info:Er kunnen slecht een maximaal aantal verschillende gebruikers toegewezen worden per dag`,
  fields: {
    maximum: {
      name: $localize`:@@constraints.maxUsers.float1:Maximum aantal gebruikers`,
      required: true,
    },
  } as Partial<Record<keyof MaxUsersPerDayConstraint, FieldEntry>>,
}
ConstraintTypeFields[ConstraintTypeEnum.MinPerMonth] = {
  name: $localize`:@@constraints.minPerMonth.title:Minimum shifts per maand`,
  info: $localize`:@@constraints.minPerMonth.info:Er moeten per maand een minimum aantal shifts van het gekozen type toegekend worden per gebruiker`,
  fields: {
    minimum: {
      name: $localize`:@@constraints.minShifts.float1:Minimum aantal shifts`,
      required: true,
    },
    shiftTypeId: {
      name: $localize`:@@constraints.max.shiftType1:Type shift`,
      required: false,
    },
  } as Partial<Record<keyof MinPerMonthConstraint, FieldEntry>>,
}
ConstraintTypeFields[ConstraintTypeEnum.MinPerWeek] = {
  name: $localize`:@@constraints.minPerWeek.title:Minimum shifts per week`,
  info: $localize`:@@constraints.minPerWeek.info:Er moeten per week een minimum aantal shifts van het gekozen type toegekend worden per gebruiker`,
  fields: ConstraintTypeFields[ConstraintTypeEnum.MinPerMonth].fields,
}
ConstraintTypeFields[ConstraintTypeEnum.MinPerYear] = {
  name: $localize`:@@constraints.minPerYear.title:Minimum shifts per planning`,
  info: $localize`:@@constraints.minPerYear.info:Er moeten voor de hele planning een minimum aantal shifts van het gekozen type toegekend worden per gebruiker`,
  fields: ConstraintTypeFields[ConstraintTypeEnum.MinPerMonth].fields,
}
ConstraintTypeFields[ConstraintTypeEnum.PeriodPreference] = {
  name: $localize`:@@constraints.periodPreference.title:Voorkeur shifttoekenning`,
  info: $localize`:@@constraints.periodPreference.info:De doelshift wordt bij voorkeur aan dezelfde gebruiker toegewezen als degene die de voorkeurshift doet, in volgorde van weergave.`,
  fields: {
    constraintPeriodId: {
      name: $localize`:@@constraints.periodPreference.float1:Doelshift`,
      required: true,
    },
    preferredPeriods: {
      name: $localize`:@@constraints.periodPreference.preferredPeriods:Voorkeurshiften voor toewijzing`,
      required: true,
    },
  } as Partial<Record<keyof PeriodPreferenceConstraint, FieldEntry>>,
}
ConstraintTypeFields[ConstraintTypeEnum.Recovery] = {
  name: $localize`:@@constraints.recovery.title:Recuperatie`,
  info: $localize`:@@constraints.recovery.info:Na een aantal werkdagen van gegeven shifttype moet een aantal recuperatiedagen volgen, waarbij weekenddagen al dan niet meegeteld worden.`,
  fields: {
    numberOfRecoveryDays: {
      name: $localize`:@@constraints.recovery.float1:Aantal recuperatiedagen`,
      required: true,
    },
    numberOfConsecutiveAssignments: {
      name: $localize`:@@constraints.recovery.float2:Aantal werkdagen`,
      required: true,
    },
    shiftTypeId: {
      name: $localize`:@@constraints.max.shiftType1:Type shift`,
      required: true,
    },
    includeWeekends: {
      name: $localize`:@@constraints.recovery.boolean1:Weekenddagen inbegrepen`,
      required: true,
    },
  } as Partial<Record<keyof RecoveryConstraint, FieldEntry>>,
}
ConstraintTypeFields[ConstraintTypeEnum.ShiftSeries] = {
  name: $localize`:@@constraints.shiftSeries.title:Shiftreeks`,
  info: $localize`:@@constraints.shiftSeries.info:Een shift moet per week steeds in een reeks van een aantal dagen aan dezelfde gebruiker worden toegekend met eventuele rustdagen na een toekenning`,
  fields: {
    seriesLength: {
      name: $localize`:@@constraints.shiftSeries.float1:Lengte van de reeks in dagen`,
      required: true,
    },
    shiftTypeId: {
      name: $localize`:@@constraints.max.shiftType1:Type shift`,
      required: true,
    },
    dayOfWeek: {
      name: $localize`:@@constraints.dayOfWeek:Startdag`,
      required: true,
    },
    numberOfRecoveryDays: {
      name: $localize`:@@constraints.recovery.float1:Aantal recuperatiedagen`,
      required: true,
    },
    excludeOnlyDifferentTypes: {
      name: $localize`:@@constraints.periodPreference.boolean1:Rustdagen omvatten enkel andere shifttypes`,
      required: true,
    },
  } as Partial<Record<keyof ShiftSeriesConstraint, FieldEntry>>,
}

ConstraintTypeFields[ConstraintTypeEnum.TagAvailability] = {
  name: $localize`:@@constraints.tagAvailability.title:Plateaubezetting`,
  info: $localize`:@@constraints.tagAvailability.info:Voor een specifiek plateau moeten altijd minstens een bepaald aantal mensen beschikbaar zijn`,
  fields: {
    tagId: {
      name: $localize`:@@constraints.tagAvailability.tagId:Plateau`,
      required: true,
    },
    availability: {
      name: $localize`:@@constraints.tagAvailability.availability:Minimumbezetting`,
      required: true,
    },
  } as Partial<Record<keyof ShiftSeriesConstraint, FieldEntry>>,
}

ConstraintTypeFields[ConstraintTypeEnum.HardcodedAssignment] = {
  name: $localize`:@@constraints.hardcodedAssignment.title:Vastgelegde shift`,
  info: $localize`:@@constraints.hardcodedAssignment.info:De shift van het gegeven shifttype op de gegeven datum moet toegewezen worden aan de gespecifiëerde persoon`,
  fields: {
    userId: {
      name: $localize`:@@constraints.hardcodedAssignment.user:Gebruiker`,
      required: true,
    },
    date: {
      name: $localize`:@@constraints.hardcodedAssignment.date:Datum`,
      required: true,
    },
    shiftTypeId: {
      name: $localize`:@@constraints.hardcodedAssignment.shiftType:Shifttype`,
      required: true,
    },
  } as Partial<Record<keyof ShiftSeriesConstraint, FieldEntry>>,
}

ConstraintTypeFields[ConstraintTypeEnum.EqualPeriodOccurrence] = {
  name: $localize`:@@constraints.equalPeriodOccurrence.title:Gelijk aantal shiften`,
  info: $localize`:@@constraints.equalPeriodOccurrence.info:De gekozen shifts moeten even vaak voorkomen`,
  fields: {
    periodId1: {
      name: $localize`:@@constraints.equalPeriodOccurrence.float1:Shift 1`,
      required: true,
    },
    periodId2: {
      name: $localize`:@@constraints.equalPeriodOccurrence.float2:Shift 2`,
      required: true,
    },
  } as Partial<Record<keyof EqualPeriodOccurrenceConstraint, FieldEntry>>,
}

const knownTypes = Object.keys(ConstraintTypeFields)
for (const type of Object.values(ConstraintTypeEnum).filter((val) => !knownTypes.includes(val))) {
  ConstraintTypeFields[type as ConstraintTypeEnum] = {
    name: $localize`:@@constraints.unknown.title:Onbekende regel`,
    info: $localize`:@@constraints.unknown.info:Dit type regel wordt niet herkend`,
    fields: {},
  }
}
