import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Version } from "@planner/models"
import { PlanningService } from "@core/services"

@Component({
  selector: "app-version-selector",
  templateUrl: "./version-selector.component.html",
})
export class VersionSelectorComponent implements OnInit {
  @Input() versions?: Version[]
  @Input() forceFittedWidth = false
  @Input() selectedVersion?: Version
  @Output() readonly selectedVersionChange = new EventEmitter<Version>()

  constructor(private planningService: PlanningService) {}

  ngOnInit() {
    if (this.versions === undefined) {
      this.planningService.getAllUnitPlanningVersions().subscribe({
        next: (versions) => {
          this.versions = versions
          if (this.versions.length === 0) {
            return
          }
          this.versions.sort(Version.Sort)
          this.selectLastVersion()
        },
      })
    } else {
      this.selectLastVersion()
    }
  }

  selectLastVersion() {
    if (
      this.selectedVersion === undefined &&
      this.versions !== undefined &&
      this.versions.length > 0
    ) {
      this.selectedVersion = this.versions[this.versions.length - 1]
      this.selectedVersionChange.emit(this.selectedVersion)
    }
  }

  selectVersion() {
    this.selectedVersionChange.emit(this.selectedVersion)
  }
}
