import { Injectable } from "@angular/core"
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router"
import { delayWhen, interval, map, Observable, of } from "rxjs"
import { AppService, AuthService } from "@core/services"

const AUTO_LOGIN_DELAY = 1000

@Injectable({
  providedIn: "root",
})
export class IsUnitPlannerGuard implements CanLoad, CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const unitId = route.paramMap.get("unitId")
    const user = this.authService.activeUser.getValue()
    if (user === undefined) {
      return this.router.createUrlTree(["/401"], { queryParams: { url: state.url } })
    }
    if (user.plannerUnits.some((unit) => unit.toString() === unitId)) {
      return true
    }
    return this.router.createUrlTree(["/403"])
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.canActivate(route, state)
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    let user = this.authService.activeUser.getValue()
    const unitId = segments[1].toString()
    if (
      this.authService.isLoggedIn() &&
      this.authService.isCurrentSessionValid() &&
      user?.plannerUnits.some((unit) => unit.toString() == unitId)
    ) {
      return of(true)
    }
    return this.appService.loading.pipe(
      // Give the application a second to try to log in
      delayWhen((loading) => (loading ? interval(AUTO_LOGIN_DELAY) : interval(0))),
      map((loading) => {
        user = this.authService.activeUser.getValue()
        if (
          !loading &&
          this.authService.isLoggedIn() &&
          this.authService.isCurrentSessionValid() &&
          user?.plannerUnits.some((unit) => unit.toString() == unitId)
        ) {
          // Loading done and we're logged in succesfully
          return true
        }
        if (user === undefined) {
          // Either still loading or not logged in
          const requestedUrl = "/" + segments.join("/")
          return this.router.createUrlTree(["/401"], { queryParams: { url: requestedUrl } })
        }
        // Logged in, but no access
        return this.router.createUrlTree(["/403"])
      }),
    )
  }
}
