<app-banner>
  <div class="m-4 rounded-lg bg-white p-4">
    <h2 class="pl-2">
      <fa-icon icon="circle-exclamation" class="pr-2"></fa-icon>
      <span i18n="@@pageNotFound.title">Pagina niet gevonden</span>
    </h2>
    <a routerLink="/" class="fluid center aligned" i18n="@@pageNotFound.returnToHome"
      >Klik hier om terug te keren naar de hoofdpagina</a
    >
  </div>
</app-banner>
