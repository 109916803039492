// eslint-disable-next-line no-shadow
export enum VersionStatus {
  Init = "INIT",
  Pending = "PENDING",
  Busy = "BUSY",
  Success = "SUCCESS",
  Failed = "FAILED",
  Canceled = "CANCELED",
}

export const LocalizedVersionStatus: Record<VersionStatus, string> = {
  INIT: $localize`:@@versionStatus.init:Klaar om uit te voeren`,
  PENDING: $localize`:@@versionStatus.pending:In afwachting`,
  BUSY: $localize`:@@versionStatus.busy:Bezig`,
  SUCCESS: $localize`:@@versionStatus.success:Succesvol`,
  FAILED: $localize`:@@versionStatus.failed:Gefaald`,
  CANCELED: $localize`:@@versionStatus.canceled:Geannuleerd`,
}
