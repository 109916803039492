import { HttpErrorResponse } from "@angular/common/http"

export class APIError {
  _fields: string[]
  _error: HttpErrorResponse
  // eslint-disable-next-line camelcase
  non_field_errors?: string[]

  constructor(httpError: HttpErrorResponse) {
    Object.assign(this, httpError.error)
    this._fields = Object.keys(httpError.error)
    this._error = httpError
  }
}
