import { Component, Input } from "@angular/core"

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent {
  @Input() label?: string

  constructor() {}
}
