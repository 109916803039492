import { Field } from "@shared/decorators"
import { PIModel, User } from "@shared/models"

export class Profile extends PIModel {
  @Field() name?: string;
  @Field() users: User[] = []
  @Field() userIds: number[] = []
  @Field() userCount = 0

  override deserialise(data: any): this {
    super.deserialise(data)

    if (data.users !== undefined) {
      this.users = data.users.map((userData: any) => new User().deserialise(userData))
    }

    return this
  }

  static override Copy(profile: any): any {
    let result = PIModel.Copy(profile)

    result.users = profile.users.map((user: any) => User.Copy(user))

    return result as Profile
  }
}
