import { CommonModule } from "@angular/common"
import { HttpClientModule } from "@angular/common/http"
import { NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { DropdownModule } from "primeng/dropdown"
import * as SharedComponents from "./components"
import * as SharedDirectives from "./directives"

@NgModule({
  declarations: [...SharedComponents.components, ...SharedDirectives.directives],
  imports: [CommonModule, FontAwesomeModule, FormsModule, HttpClientModule, DropdownModule],
  exports: [
    FontAwesomeModule,
    FormsModule,
    CommonModule,
    ...SharedComponents.components,
    ...SharedDirectives.directives,
  ],
})
export class SharedModule {}
