import { Field, Serialisable } from "../decorators"

/**
 * Superclass used for all models retrieved from the server. This class implements some utility functions to make it easier to
 * (de)serialise data, track, sort, or copy models.
 */
@Serialisable
export class PIModel {
  @Field() id = -1

  constructor(id: number = -1) {
    this.id = id
  }

  /**
   * @returns A serialised representation of this model
   */
  serialise(): Record<string, any> {
    return {}
  }

  /**
   * Overwrite this instance's model fields with the values found in data.
   * @param data Data received from the server
   * @returns this instance
   */
  deserialise(data: Record<string, any>): this {
    // eslint-disable-next-line no-unused-expressions
    data
    return this
  }

  /**
   * Returns a unique parameter that can be used to uniquely track the given model.
   * @param model The model to track
   * @returns The tracking parameter
   */
  static Track(_index: number, model: PIModel): number | string {
    return model.id
  }

  /**
   * Sort two models. Defined so it can be used as a lambda in Array.sort().
   */
  static Sort(m1: PIModel, m2: PIModel): number {
    return m1.id - m2.id
  }

  /**
   * @param model The model to copy
   * @returns A deep copy of the provided model
   */
  static Copy<T extends PIModel>(model: T): T {
    let m = model as any
    var copy = new m.constructor() as T
    copy.deserialise(model.serialise())
    return copy
  }
}
