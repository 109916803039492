import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@shared/guards"

const routes: Routes = [
  {
    path: "units",
    loadChildren: () => import("./units/units.module").then((m) => m.UnitsModule),
    canLoad: [AuthGuard],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
