import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core"
import { Subject, Subscription } from "rxjs"
import { debounceTime, distinctUntilChanged } from "rxjs/operators"

const DEFAULT_DEBOUNCE_TIME = 350 // In ms

@Directive({
  selector: "[appDebounce]",
})
export class DebounceDirective implements OnInit, OnDestroy {
  @Output() readonly debounceEvent: EventEmitter<any> = new EventEmitter<any>()
  @Input() appDebounce: number | string = "" // In ms

  protected emitEvent: Subject<any> = new Subject<any>()
  protected subscription?: Subscription

  ngOnInit() {
    if (typeof this.appDebounce == "string") {
      this.appDebounce = DEFAULT_DEBOUNCE_TIME
    }

    this.subscription = this.emitEvent
      .pipe(debounceTime(this.appDebounce), distinctUntilChanged())
      .subscribe((value) => this.debounceEvent.emit(value))
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }

  @HostListener("keyup", ["$event"])
  public onKeyUp(event: KeyboardEvent): void {
    if (event.target) {
      let target = event.target as HTMLInputElement
      this.emitEvent.next(target.value)
    } else {
      this.emitEvent.next(event)
    }
  }

  @HostListener("click", ["$event"])
  clickEvent(event: MouseEvent) {
    if (event.target) {
      let target = event.target as HTMLInputElement
      this.emitEvent.next(target.value)
    } else {
      this.emitEvent.next(event)
    }
  }
}
