<div class="flex flex-col">
    <label for="versionDropdownSelect" i18n="@@version">Versie</label>
    <p-dropdown
        *ngIf="versions !== undefined"
        id="versionDropdownSelect"
        optionLabel="createdAt"
        [styleClass]="forceFittedWidth ? 'w-full sm:w-fit' : 'w-full'"
        [options]="versions"
        [(ngModel)]="selectedVersion"
        (ngModelChange)="selectVersion()"
    >
        <ng-template pTemplate="selectedItem">
            <ng-container *ngIf="selectedVersion !== undefined">
                ({{ selectedVersion.id }}) - {{ selectedVersion.createdAt | date : 'medium' }}
            </ng-container>
        </ng-template>
        <ng-template let-version pTemplate="item"> ({{ version.id }}) - {{ version.createdAt | date : 'medium' }} </ng-template>
    </p-dropdown>
</div>
