import { enableProdMode } from "@angular/core"
// Load i18n files at runtime
import { loadTranslations } from "@angular/localize"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"
import { AppModule } from "@/app.module"
import { environment } from "@env"
import "@angular/localize/init"
import { detectLanguage } from "@shared/utils/detect-language"
import { getTranslation } from "@shared/utils/get-translation"

if (environment.production) {
  enableProdMode()
}

let localeId = localStorage.getItem(environment.storageKeys.LOCALE) ?? detectLanguage()

if (localeId == undefined || !(localeId in environment.locales)) {
  localeId = environment.defaultLocale
}

getTranslation(`/assets/i18n/${localeId}.json`).then(
  (data) => {
    if (data) {
      loadTranslations(data.translations)
    }
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .then((ref) => {
        const wind = window as any
        if (wind.ngRef) {
          wind.ngRef.destroy()
        }
        wind.ngRef = ref
      })
      .catch((error) => {
        console.error("[main.ts] Error during AppModule bootstrapping:", error)
      })
  },
  (error) => {
    console.error(`[main.ts] Error while loading locale ${localeId}:`, error)
    console.error("-> Trying to bootstrap using the source locale.")
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) =>
        console.error("[main.ts] Error during source-locale AppModule bootstrapping:", err),
      )
  },
)
