import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { User, UserSummary } from "@shared/models"
import { PIEndpoint, PIEndpointBuilder } from "@shared/utils/endpoint-builder"
import { environment } from "@env"

@Injectable({
  providedIn: "root",
})
export class UserService {
  listEndpoint: PIEndpoint<UserSummary>
  endPoint: PIEndpoint<User>

  constructor(private endPointBuilder: PIEndpointBuilder) {
    this.listEndpoint = this.endPointBuilder.initialise(UserSummary, environment.api, "/users/")
    this.endPoint = this.endPointBuilder.initialise(
      User,
      environment.api,
      (data: { id: number }) => {
        if (data?.id) {
          return `/users/${data.id}`
        } else {
          return `/users/`
        }
      },
    )
  }

  getAllUsers = () => this.listEndpoint.get() as Observable<UserSummary[]>
  getUser = (userId: number) => this.endPoint.get({ url: { id: userId } })
  updateUser = (userId: number, user: User) => this.endPoint.patch(user, { url: { id: userId } })
  deleteUser = (userId: number) => this.endPoint.delete({ url: { id: userId } })
}
