import { Deserialise, Field } from "@shared/decorators"
import { PIModel } from "@shared/models"

export class Planning extends PIModel {
  @Deserialise() unit = 0
  @Field() name?: string
  @Field() start = new Date()
  @Field() end = new Date()
  @Field() published = false

  constructor() {
    super()
    this.end.setFullYear(this.start.getFullYear() + 1)
  }

  override deserialise(data: any): this {
    // Override to deal with dates correctly
    super.deserialise(data)
    this.name = this.name === "" ? undefined : this.name
    this.start = data.start != null ? new Date(data.start) : this.start
    this.end = data.end != null ? new Date(data.end) : this.end
    return this
  }

  override serialise(): Record<string, any> {
    // Override to deal with dates correctly
    const val = super.serialise()
    const datelen = 10
    val["start"] = this.start.toISOString().slice(0, datelen)
    val["end"] = this.end.toISOString().slice(0, datelen)
    return val
  }
}
