import { EventInput } from "@fullcalendar/core"
import { Deserialise, Field } from "@shared/decorators"
import { PIModel } from "@shared/models"

export class Assignment extends PIModel {
  @Field() userId = 0
  @Deserialise() userName = "<Anonymous>"
  @Deserialise() userEmail = "<Anonymous>"
  @Deserialise() date: Date = new Date()
  @Deserialise() start = "00:00"
  @Deserialise() end = "23:59"
  @Deserialise() type = ""

  override deserialise(data: any): this {
    // Override to deal with date
    super.deserialise(data)
    this.date = data.date ? new Date(data.date) : this.date
    return this
  }

  static override Sort(a1: Assignment, a2: Assignment): number {
    return (
      a1.date.toISOString().localeCompare(a2.date.toISOString()) ||
      a1.type.localeCompare(a2.type) ||
      a1.start.localeCompare(a2.start)
    )
  }

  getStartAsDate(): Date {
    let start = new Date(this.date)
    start.setHours(Number(this.start.split(":")[0]))
    start.setMinutes(Number(this.start.split(":")[1]))

    return start
  }

  getEndAsDate(): Date {
    let end = new Date(this.date)
    end.setHours(Number(this.end.split(":")[0]))
    end.setMinutes(Number(this.end.split(":")[1]))

    if (this.start >= this.end) {
      end.setDate(end.getDate() + 1)
    }

    return end
  }

  toCalendarEvent(): EventInput {
    return {
      id: this.id.toString(),
      start: this.getStartAsDate(),
      end: this.getEndAsDate(),
      title: `${this.type} (${this.start} - ${this.end}) - ${this.userName}`,
    }
  }
}
