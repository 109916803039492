import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { map, Observable, throwError } from "rxjs"
import { PIModel } from "../models"

export type Param = string | number | boolean
export type QueryParams = HttpParams | Record<string, Param>
export type PIEndpointFunction = (params: UrlParams) => string
export type UrlParams = any
export type PIEndpointOptions = {
  action?: string | number
  query?: QueryParams
  url?: UrlParams
}

export interface PIHeaders {
  [name: string]: string | string[]
}

@Injectable({
  providedIn: "root",
})
export class PIEndpointBuilder {
  constructor(private http: HttpClient) {}

  initialise<T extends PIModel>(
    type: typeof PIModel | undefined,
    base: string,
    endpoint: string | PIEndpointFunction,
    headers: PIHeaders = {},
  ): PIEndpoint<T> {
    return new PIEndpoint<T>(this.http, base, endpoint, headers, type)
  }

  initialiseVoid(
    base: string,
    endpoint: string | PIEndpointFunction,
    headers: PIHeaders = {},
  ): PIEndpoint<void> {
    return new PIEndpoint<void>(this.http, base, endpoint, headers, undefined)
  }
}

export class PIEndpoint<T extends PIModel | void> {
  private _base?: string
  private _endpoint?: string
  private _endpointFn?: PIEndpointFunction
  private headers = new HttpHeaders()
  private _prototype?: T

  debug = false

  constructor(
    private http: HttpClient,
    base: string,
    endpoint: string | PIEndpointFunction,
    headers: PIHeaders,
    typeClass?: typeof PIModel,
  ) {
    if (typeClass) {
      this._prototype = <T>new typeClass()
    }
    if (base.endsWith("/")) {
      this._base = base.slice(0, base.length - 1)
    } else {
      this._base = base
    }

    if (typeof endpoint === "string") {
      this._endpoint = endpoint
      this._endpointFn = undefined
    } else {
      this._endpoint = undefined
      this._endpointFn = endpoint
    }
    for (let header of Object.entries(headers)) {
      this.setHeader(header[0], header[1])
    }
  }

  getUrl(params?: UrlParams): string {
    let endpoint = this._endpoint ?? ""
    if (this._endpoint === undefined) {
      endpoint = this._endpointFn?.(params ?? {}) ?? ""
    }
    if (endpoint.endsWith("/")) {
      endpoint = endpoint.slice(0, endpoint.length - 1)
    }
    return `${this._base ?? ""}${endpoint}`
  }

  private getEndpoint(options: PIEndpointOptions): string {
    let endpoint = options.action ?? ""

    if (typeof endpoint !== "string" || !endpoint.startsWith("/")) {
      endpoint = `/${endpoint}`
    }

    return `${this.getUrl(options.url)}${endpoint}`
  }

  private checkParams(method: string, options: PIEndpointOptions): string | undefined {
    if (!this.getUrl(options.url)) {
      return `[PIEndpoint] Trying to call '${method}' on an endpoint that has not been initialised yet. Call 'setEndpoint' to initialise this.`
    }

    return undefined
  }

  // Recursively deserialise the provided data with the endpoint prototype
  private deserialise(data: any): any {
    if (Array.isArray(data)) {
      return data.map((d) => this.deserialise(d))
    } else {
      let p = this._prototype as any
      let obj = new p.constructor()
      return obj.deserialise(data)
    }
  }

  private mapReply(data: any) {
    if (this._prototype === undefined || typeof data !== "object") {
      // No prototype provided so we can't deserialise this, or it isn't an object.
      return data
    } else {
      return this.deserialise(data)
    }
  }

  setHeader(header: string, value: string | string[]): void {
    this.headers = this.headers.set(header, value)
  }

  get(options: PIEndpointOptions = {}): Observable<T | T[]> {
    const error = this.checkParams("get", options)
    if (error) {
      console.error(error)
      return throwError(() => error)
    }

    const url = this.getEndpoint(options)
    const _options = {
      headers: this.headers,
      params: options.query,
    }

    if (this.debug) {
      console.debug(`GET ${url}, options:`, _options)
    }
    return this.http.get<T | T[]>(url, _options).pipe(map((data) => this.mapReply(data)))
  }

  post(model?: PIModel, options: PIEndpointOptions = {}): Observable<T> {
    const error = this.checkParams("post", options)
    if (error) {
      console.error(error)
      return throwError(() => error)
    }

    const url = this.getEndpoint(options)
    const _options = {
      headers: this.headers,
      params: options.query,
    }

    if (this.debug) {
      console.debug(`POST ${url}, options:`, _options, " model:", model)
    }
    return this.http
      .post<T>(url, model?.serialise(), _options)
      .pipe(map((data) => this.mapReply(data)))
  }

  put(model?: PIModel, options: PIEndpointOptions = {}): Observable<T> {
    const error = this.checkParams("put", options)
    if (error) {
      console.error(error)
      return throwError(() => error)
    }

    const url = this.getEndpoint(options)
    const _options = {
      headers: this.headers,
      params: options.query,
    }

    if (this.debug) {
      console.debug(`PUT ${url}, options:`, _options, " model:", model)
    }
    return this.http
      .put<T>(url, model?.serialise(), _options)
      .pipe(map((data) => this.mapReply(data)))
  }

  patch(model?: PIModel, options: PIEndpointOptions = {}): Observable<T> {
    const error = this.checkParams("patch", options)
    if (error) {
      console.error(error)
      return throwError(() => error)
    }

    const url = this.getEndpoint(options)
    const _options = {
      headers: this.headers,
      params: options.query,
    }

    if (this.debug) {
      console.debug(`PATCH ${url}, options:`, _options, " model:", model)
    }
    return this.http
      .patch<T>(url, model?.serialise(), _options)
      .pipe(map((data) => this.mapReply(data)))
  }

  delete(options: PIEndpointOptions = {}): Observable<void> {
    const error = this.checkParams("delete", options)
    if (error) {
      console.error(error)
      return throwError(() => error)
    }

    const url = this.getEndpoint(options)
    const _options = {
      headers: this.headers,
      params: options.query,
    }

    if (this.debug) {
      console.debug(`DELETE ${url}, options:`, _options)
    }
    return this.http.delete<void>(url, _options)
  }
}
