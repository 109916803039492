import { convertPropertyName } from "@shared/utils/convert-property-name"

/**
 * Mark a model property as deserialisable. Properties with this decorator will be deserialised if they're available on the deserialise data.
 * The decorator will automatically try to convert from a snake_case name to the bound property's name.
 *
 * @param key [Optional] Key that will be used instead of the automatically generated one.
 **/
export function Deserialise(key?: string) {
  return (target: any, bindingPropertyName?: string): any => {
    if (!target._invSerialiseFields) {
      target._invSerialiseFields = {}
    }
    if (bindingPropertyName != undefined) {
      var prop = `${target.constructor.name}#${convertPropertyName(bindingPropertyName, key)}`
      if (target._invSerialiseFields[prop] != undefined) {
        console.error(
          `[@serialise()] Detected multiple properties with the same serialiser key: ${prop} bound to ${target._invSerialiseFields[prop]} and ${bindingPropertyName}. This is not allowed, please fix!`,
        )
      } else {
        target._invSerialiseFields[prop] = bindingPropertyName
      }
    }
  }
}
