/* eslint-disable dot-notation */
import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { AppService, AuthService } from "@core/services"
import { NotificationService } from "@core/services/notification.service"

@Component({
  selector: "app-unauthenticated",
  templateUrl: "./unauthenticated.component.html",
  styleUrls: ["./unauthenticated.component.scss"],
})
export class UnauthenticatedComponent implements OnInit {
  constructor(
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.appService.loading.subscribe((isLoading) => {
      if (!isLoading) {
        if (this.authService.isLoggedIn()) {
          const path = this.authService.popLoginRedirect()
          this.router.navigateByUrl(path, { replaceUrl: true })
        }
      }
    })

    this.route.queryParams.subscribe((params) => {
      if (params["url"]) {
        this.authService.loginRedirect = params["url"]
      }
    })

    this.notificationService.showErrorMessage(
      $localize`:@@unauthenticated.description:Om deze pagina te kunnen bekijken moet je aangemeld zijn`,
    )
  }
}
