import { Deserialise } from "@shared/decorators"
import { PIModel, User } from "@shared/models"

export class TokenData extends PIModel {
  @Deserialise() token = ""
  @Deserialise() tokenExpiry = ""
  @Deserialise() loginToken = ""
  @Deserialise() loginTokenExpiry = ""
  @Deserialise() user = new User()
}
