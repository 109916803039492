<div class="container">
  <div class="grid grid-cols-12 gap-0 pt-5">
    <div class="col-span-6 col-start-3">
      <div class="alert" [class.danger]="error" [class.success]="!error" role="alert">
        <h2 *ngIf="!error; else errorMessage" class="pl-2">
          <fa-icon icon="check" class="pr-2"></fa-icon>
          <span i18n="@@logout.title">Je bent nu uitgelogd</span>
        </h2>
        <a routerLink="/" class="fluid center aligned" i18n="@@pageNotFound.returnToHome"
          >Klik hier om terug te keren naar de hoofdpagina</a
        >
      </div>
    </div>
  </div>
</div>

<ng-template #errorMessage>
  <h2 *ngIf="!error; else errorMessage" class="pl-2">
    <fa-icon icon="circle-exclamation" class="pr-2"></fa-icon>
    <span i18n="@@logout.error.title">Er ging iets mis bij het uitloggen</span>
  </h2>
</ng-template>
