import { Field } from "@shared/decorators"
import { PIModel } from "@shared/models"

export class TagType extends PIModel {
  @Field() name = "unknown tag type"

  static override Sort(tt1: TagType, tt2: TagType): number {
    return tt1.name.localeCompare(tt2.name) <= 0 ? -1 : 1
  }
}
