import { Buffer } from "buffer"
import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { AuthService } from "@core/services"
import { NotificationService } from "@core/services/notification.service"
import { PasswordResetConfirmData } from "@public/models"

@Component({
  selector: "app-password-reset-confirm",
  templateUrl: "./password-reset-confirm.component.html",
  styleUrls: ["./password-reset-confirm.component.scss"],
})
export class PasswordResetConfirmComponent implements OnInit {
  invalidToken = false
  resetInfo = new PasswordResetConfirmData()
  submitted = false
  isLoading = false
  resetSuccess = false
  errors = {
    password1: undefined,
    password2: undefined,
    email: undefined,
    general: undefined,
  } as Record<string, string[] | undefined>

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    const requiredNumberOfTokenParts = 3
    const tokenParts = (this.route.snapshot.paramMap.get("token") ?? "").split("_")
    if (tokenParts.length !== requiredNumberOfTokenParts) {
      this.notificationService.showErrorMessage(
        $localize`:@@passwordReset.invalidToken:Het opnieuw instellen van je wachtwoord is niet mogelijk door een ongeldig token`,
      )
      this.invalidToken = true
      return
    }
    this.resetInfo.email = Buffer.from(tokenParts[1], "base64").toString("ascii")
    this.resetInfo.token = tokenParts[2]
  }

  validate(): boolean {
    this.errors["password1"] = undefined
    this.errors["password2"] = undefined
    if (this.resetInfo.password1 === "") {
      this.errors["password1"] = [$localize`:@@fieldRequired:Dit veld is vereist`]
    }
    if (this.resetInfo.password2 === "") {
      this.errors["password2"] = [$localize`:@@fieldRequired:Dit veld is vereist`]
    }
    if (this.resetInfo.password1 !== this.resetInfo.password2) {
      this.errors["password2"] = (this.errors["password2"] ?? []).concat([
        $localize`:@@passwordReset.errors.notEqual:De twee wachtwoorden moeten hetzelfde zijn`,
      ])
    }

    return Object.values(this.errors).every((val) => val === undefined)
  }

  onSubmit(): void {
    this.isLoading = true
    this.submitted = true
    if (!this.validate()) {
      this.isLoading = false
      return
    }
    this.authService.confirmPasswordReset(this.resetInfo).subscribe({
      next: (_) => {
        this.resetSuccess = true
        this.isLoading = false
      },
      error: (err) => {
        this.notificationService.showErrorMessage(
          $localize`:@@passwordReset.error.couldNotConfirm:Opnieuw instellen van je wachtwoord is momenteel niet mogelijk. Probeer later opnieuw.`,
        )
        console.error("error while confirming password", err)
        this.setServerErrors(err)
        this.isLoading = false
      },
    })
  }

  setServerErrors(err: any): void {
    if (err.token || (err.error && err.error.includes("token"))) {
      this.errors["general"] = [
        $localize`:@@passwordReset.errors.invalidServerToken:Het gebruikte token is niet geldig. Gelieve een nieuwe aanvraag tot reset in te dienen.`,
      ]
    }
    if (err.password1) {
      this.errors["password1"] = [
        $localize`:@@passwordReset.errors.invalidPassword:Het wachtwoord is niet toegestaan`,
      ]
    }
    if (err.password2) {
      this.errors["password2"] = [
        $localize`:@@passwordReset.errors.invalidPassword:Het wachtwoord is niet toegestaan`,
      ]
    }
    if (err.email) {
      this.errors["email"] = [
        $localize`:@@passwordReset.errors.invalidEmail:Dit e-mailadres is ongeldig. Gelieve een nieuwe aanvraag tot reset in te dienen.`,
      ]
    }
    if (err._fields.length == 0) {
      this.errors["general"] = [
        $localize`:@@errors.unknownError:Er is een onbekende fout opgetreden. Probeer het later opnieuw.`,
      ]
    }
  }

  trackValue(_idx: number, val: string): string {
    return val
  }
}
