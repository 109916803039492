import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Assignment } from "@planner/models"
import { PlanningService } from "@core/services/planning.service"
import { UnitService } from "@core/services/unit.service"
import { PIEndpoint, PIEndpointBuilder } from "@shared/utils/endpoint-builder"
import { environment } from "@env"

@Injectable({
  providedIn: "root",
})
export class AssignmentService {
  endPoint: PIEndpoint<Assignment>

  constructor(
    private endPointBuilder: PIEndpointBuilder,
    private unitService: UnitService,
    private planningService: PlanningService,
  ) {
    this.endPoint = this.endPointBuilder.initialise(
      Assignment,
      environment.api,
      (data: { versionId: number; id: number }) => {
        let unitId = this.unitService.getCurrentUnitId()
        let planningId = this.planningService.getCurrentPlanningId()

        if (data?.id) {
          return `/units/${unitId}/plannings/${planningId}/versions/${data.versionId}/assignments/${data.id}`
        } else {
          return `/units/${unitId}/plannings/${planningId}/versions/${data.versionId}/assignments/`
        }
      },
    )
  }

  getAllUnitPlanningVersionAssignments = (versionId: number) =>
    this.endPoint.get({ url: { versionId: versionId } }) as Observable<Assignment[]>

  getUnitPlanningVersionAssignment = (versionId: number, assignmentId: number) =>
    this.endPoint.get({ url: { versionId: versionId, id: assignmentId } })

  createUnitPlanningVersionAssignment = (versionId: number, assignment: Assignment) =>
    this.endPoint.post(assignment, { url: { versionId: versionId } })

  updateUnitPlanningVersionAssignment = (versionId: number, assignment: Assignment) =>
    this.endPoint.patch(assignment, { url: { versionId: versionId, id: assignment.id } })

  deleteUnitPlanningVersionAssignment = (versionId: number, assignmentId: number) =>
    this.endPoint.delete({ url: { versionId: versionId, id: assignmentId } })
}
