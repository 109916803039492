import { Injectable } from "@angular/core"
import { Title } from "@angular/platform-browser"
import { BehaviorSubject, Observable } from "rxjs"
import { Planning } from "app/planner/models"
import { environment } from "@env"

@Injectable({
  providedIn: "root",
})
export class AppService {
  private uniqueCounter = 0

  public loading = new BehaviorSubject(true)
  public browserTitle: BehaviorSubject<string>
  private locale: BehaviorSubject<string>

  constructor(private titleService: Title) {
    this.browserTitle = new BehaviorSubject(environment.applicationName)
    this.browserTitle.subscribe((title) => this.titleService.setTitle(title))
    this.locale = new BehaviorSubject(this.detectPreferredLocale())
    this.locale.subscribe((newLocale) => {
      console.debug(`[AppService] Locale changed to: ${newLocale}`)
      if (newLocale !== undefined) {
        localStorage.setItem(environment.storageKeys.LOCALE, newLocale)
      }
    })
  }

  public get browserName(): string {
    const userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.indexOf("opera") > -1 || userAgent.indexOf("opr") > -1) {
      return "Opera"
    } else if (userAgent.indexOf("chrome") > -1) {
      return "Chrome"
    } else if (userAgent.indexOf("safari") > -1) {
      return "Safari"
    } else if (userAgent.indexOf("firefox") > -1) {
      return "Firefox"
    } else if (userAgent.indexOf("msie") > -1) {
      return "IE"
    }
    console.error("Browser type could not be determined from the following user agent:", userAgent)
    return "<Undefined>"
  }

  public setSubTitle(title: string): void {
    this.browserTitle.next(`${title} - ${environment.applicationName}`)
  }

  public setDefaultSubTitle(): void {
    this.browserTitle.next(environment.applicationName)
  }

  public getLocale(): Observable<string> {
    return this.locale
  }

  public getCurrentLocale(): string {
    return this.locale.getValue() ?? this.detectPreferredLocale()
  }

  public setLocale(newLocale: string) {
    if (!(newLocale in environment.locales)) {
      console.debug(`[AppService] Locale not supported: ${newLocale}!`)
    } else if (newLocale !== this.locale.getValue()) {
      this.locale.next(newLocale)
    }
  }

  public clearStoredLocale(): void {
    localStorage.removeItem(environment.storageKeys.LOCALE)
  }

  private detectPreferredLocale(): string {
    const localeSet = localStorage.getItem(environment.storageKeys.LOCALE) as string
    if (localeSet !== undefined && localeSet in environment.locales) {
      console.debug(`[AppService] Locale detected: ${localeSet}`)
      return localeSet
    }

    // If not set already, try to detect the best possible match from the browser settings.
    const preferredLocale = window?.navigator?.languages?.find((loc) => loc in environment.locales)
    console.debug(`[AppService] Locale detected: ${preferredLocale}`)
    return preferredLocale ?? window?.navigator?.language ?? environment.defaultLocale
  }

  public getUniqueIdentifier(): number {
    return this.uniqueCounter++
  }
}
