import { Injectable } from "@angular/core"
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router"
import { interval, Observable, of } from "rxjs"
import { delayWhen, map } from "rxjs/operators"
import { AppService, AuthService } from "@core/services"

const delayInMs = 1000
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.authService.isLoggedIn() && this.authService.isCurrentSessionValid()) {
      return true
    }
    return this.router.createUrlTree(["/401"], { queryParams: { url: state.url } })
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    return this.canActivate(childRoute, state)
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    if (this.authService.isLoggedIn() && this.authService.isCurrentSessionValid()) {
      return of(true)
    }

    return this.appService.loading.pipe(
      // Give the application a second to try to log in
      delayWhen((loading) => (loading ? interval(delayInMs) : interval(0))),
      map((loading) => {
        if (!loading && this.authService.isLoggedIn() && this.authService.isCurrentSessionValid()) {
          // Loading done and we're logged in succesfully
          return true
        }
        // Either still loading or not logged in
        const requestedUrl = "/" + segments.join("/")
        return this.router.createUrlTree(["/401"], { queryParams: { url: requestedUrl } })
      }),
    )
  }
}
