import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { LoginComponent } from "@shared/components"
import { ForbiddenComponent } from "./forbidden/forbidden.component"
import { LandingRedirectComponent } from "./landing-redirect/landing-redirect.component"
import { LogoutComponent } from "./logout/logout.component"
import { NotFoundComponent } from "./not-found/not-found.component"
import { PasswordResetConfirmComponent } from "./password-reset-confirm/password-reset-confirm.component"
import { UnauthenticatedComponent } from "./unauthenticated/unauthenticated.component"

const routes: Routes = [
  { path: "home", component: LandingRedirectComponent },
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  { path: "reset-password/:token", component: PasswordResetConfirmComponent },
  { path: "authentication-requested", component: UnauthenticatedComponent },
  { path: "forbidden", component: ForbiddenComponent },
  { path: "notfound", component: NotFoundComponent },
  { path: "401", redirectTo: "authentication-requested" },
  { path: "403", redirectTo: "forbidden" },
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "**", redirectTo: "notfound" },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
