import { Deserialise } from "@shared/decorators"
import { PIModel } from "@shared/models"
import { ConstraintTypeEnum } from "./constraint-type.enum"

export class ConstraintType extends PIModel {
  @Deserialise() type = ConstraintTypeEnum.Unknown
  @Deserialise() canBeUnitConstraint = false
  @Deserialise() canBeProfileConstraint = false
}

export type ConstraintTypeOption = {
  name: string
  content: ConstraintType
}
