import { Injectable } from "@angular/core"
import { Assignment } from "app/planner/models"
import { format } from "date-fns"
import * as FileSaver from "file-saver"
import { createEvents, DateArray, EventAttributes } from "ics"
import * as XLSX from "xlsx"
import { NotificationService } from "@core/services"

const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
const EXCEL_EXTENSION = ".xlsx"
export type IcsEvent = {
  start: Date
  end: Date
  title: string
  description: string
  organizer: { name: string; email: string }
}

@Injectable({
  providedIn: "root",
})
export class ExportService {
  constructor(private notificationService: NotificationService) {}

  public exportPlanningAsExcelFile(headers: string[], data: any[], fileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(worksheet, [headers])
    XLSX.utils.sheet_add_json(worksheet, data, { origin: "A2", skipHeader: true })
    const workbook: XLSX.WorkBook = { Sheets: { Planning: worksheet }, SheetNames: ["Planning"] }
    const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
    ExportService.saveAsExcelFile(excelBuffer, fileName)
  }

  public generateIcsStringFromPlanning(assignments: Assignment[]): string | undefined {
    const transformedData: EventAttributes[] = assignments.map((assignment) => {
      return {
        start: format(assignment.getStartAsDate(), "yyyy-M-d-H-m")
          .split("-")
          .map((str) => Number(str)) as DateArray,
        end: format(assignment.getEndAsDate(), "yyyy-M-d-H-m")
          .split("-")
          .map((str) => Number(str)) as DateArray,
        title: assignment.type,
        description: `${assignment.type} (${assignment.start}-${assignment.end})`,
        organizer: { name: assignment.userName, email: assignment.userEmail },
      }
    })
    const icsString = createEvents(transformedData)

    if (icsString.error != undefined) {
      this.notificationService.showErrorMessage(
        $localize`:@@icsExport.error.generateIcs:Er ging iets fout tijdens het genereren van het ics-bestand. Probeer later opnieuw.`,
      )
      console.error("error generating ics string", icsString.error)
      return undefined
    }

    return icsString.value
  }

  private static saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    })
    FileSaver.saveAs(data, `${fileName}${EXCEL_EXTENSION}`)
  }
}
