import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { filter } from "rxjs"
import { AppService, AuthService } from "@core/services"
import { environment } from "@env"

@Component({
  selector: "app-landing-redirect",
  template: "<app-loading></app-loading>",
})
export class LandingRedirectComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.appService.loading.pipe(filter((val) => val === false)).subscribe({
      next: (_) => this.doRedirect(),
    })
  }

  private doRedirect(): void {
    if (this.authService.isLoggedIn()) {
      const path = this.authService.popLoginRedirect()
      this.router.navigateByUrl(path, { replaceUrl: true })
    } else {
      this.router.navigateByUrl(environment.defaultLoginPath, { replaceUrl: true })
    }
  }
}
