function findParentClasses(object: Object): string[] {
  let prototype = Object.getPrototypeOf(object)
  let classes = [prototype.constructor.name]
  while (prototype.constructor.name != "PIModel") {
    prototype = Object.getPrototypeOf(prototype)
    classes.push(prototype.constructor.name)
  }
  return classes
}

function deserialiseField(data: any, value: any): any {
  if (data === null) {
    return undefined
  } else if (typeof data == "object") {
    let model = new value.constructor()
    if (typeof model.deserialise === "function") {
      return model.deserialise(data)
    } else {
      return data
    }
  } else {
    return data
  }
}

function serialiseField(value: any): any {
  if (value === null) {
    return null
  } else if (typeof value === "object" && typeof value.serialise === "function") {
    return value.serialise()
  } else {
    return value
  }
}

/**
 * Class decorator that marks a class as serialisable.
 * Classes adding this decorator should define an interface for the following methods:
 *   - serialise(): Record<string, any>
 *   - deserialise(data: any): this
 *  This interface is provided automatically when using the PIModel superclass
 **/
export function Serialisable(constructor: Function) {
  if (!constructor.prototype._serialiseFields) {
    constructor.prototype._serialiseFields = {}
  }
  if (!constructor.prototype._invSerialiseFields) {
    constructor.prototype._invSerialiseFields = {}
  }

  constructor.prototype.serialise = function (): Record<string, any> {
    if (!this._parentClasses) {
      this._parentClasses = findParentClasses(this)
    }

    var returnVal = {} as Record<string, any>
    for (let k of Object.keys(this)) {
      for (let parent of this._parentClasses) {
        const key = `${parent}#${k}`
        if (this._serialiseFields[key]) {
          const value = serialiseField(this[k])
          returnVal[this._serialiseFields[key]] = value
          break
        }
      }
    }
    return returnVal
  }

  constructor.prototype.deserialise = function (data: any) {
    if (!this._parentClasses) {
      this._parentClasses = findParentClasses(this)
    }

    for (let k of Object.keys(data)) {
      for (let parent of this._parentClasses) {
        const field = this._invSerialiseFields[`${parent}#${k}`]
        if (field) {
          this[field] = deserialiseField(data[k], this[field])
          break
        }
      }
    }
    return this
  }
}
