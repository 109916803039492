import { Deserialise, Field } from "@shared/decorators"
import { PIModel } from "@shared/models"

export const weekdays = [
  $localize`:@@monday:Maandag`,
  $localize`:@@tuesday:Dinsdag`,
  $localize`:@@wednesday:Woensdag`,
  $localize`:@@thursday:Donderdag`,
  $localize`:@@friday:Vrijdag`,
  $localize`:@@saturday:Zaterdag`,
  $localize`:@@sunday:Zondag`,
]

export class Period extends PIModel {
  @Field() shiftType? = 0
  @Deserialise() shiftTypeName = ""
  @Field() start = ""
  @Field() end = ""
  @Field() dayOfWeek?: number
  @Field() date?: Date

  override serialise(): Record<string, any> {
    const data = super.serialise()
    const datelen = 10
    data["date"] = this.date?.toISOString().slice(0, datelen)
    return data
  }

  override deserialise(data: any): this {
    super.deserialise(data)
    this.date = data.date ? new Date(data.date) : this.date
    return this
  }

  static override Sort(p1: Period, p2: Period): number {
    if (p1.start === p2.start) {
      return p1.shiftTypeName.localeCompare(p2.shiftTypeName) <= 0 ? -1 : 1
    } else {
      return p1.start.localeCompare(p2.start) <= 0 ? -1 : 1
    }
  }

  static override Copy(p: any): any {
    let result = PIModel.Copy(p)
    result.shiftTypeName = p.shiftTypeName
    return result as Period
  }

  override toString(): string {
    const hourMinLen = 5
    return `${this.shiftTypeName}: ${
      this.date ? this.date.toLocaleDateString() : weekdays[this.dayOfWeek!]
    } ${this.start.slice(0, hourMinLen)} - ${this.end.slice(0, hourMinLen)}`
  }
}
