import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { ProfileWorkload } from "@planner/models/profile-workload"
import { Profile } from "app/planner/models"
import { PlanningService, UnitService } from "@core/services"
import { PIEndpoint, PIEndpointBuilder } from "@shared/utils/endpoint-builder"
import { environment } from "@env"

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  profileEndpoint: PIEndpoint<Profile>
  profileWorkloadEndpoint: PIEndpoint<ProfileWorkload>

  constructor(
    private endPointBuilder: PIEndpointBuilder,
    private unitService: UnitService,
    private planningService: PlanningService,
  ) {
    this.profileEndpoint = this.endPointBuilder.initialise(
      Profile,
      environment.api,
      (data: { id: number }) => {
        if (data?.id) {
          return `/units/${unitService.getCurrentUnitId()}/plannings/${planningService.getCurrentPlanningId()}/profiles/${
            data.id
          }`
        } else {
          return `/units/${unitService.getCurrentUnitId()}/plannings/${planningService.getCurrentPlanningId()}/profiles/`
        }
      },
    )
    this.profileWorkloadEndpoint = this.endPointBuilder.initialise(
      ProfileWorkload,
      environment.api,
      (data: { profileId: number; id: number }) => {
        if (data?.id) {
          return `/units/${unitService.getCurrentUnitId()}/plannings/${planningService.getCurrentPlanningId()}/profiles/${
            data.profileId
          }/workloads/${data.id}`
        } else {
          return `/units/${unitService.getCurrentUnitId()}/plannings/${planningService.getCurrentPlanningId()}/profiles/${
            data.profileId
          }/workloads/`
        }
      },
    )
  }

  getAllUnitPlanningProfiles = () => this.profileEndpoint.get() as Observable<Profile[]>
  getUnitPlanningProfile = (profileId: number) =>
    this.profileEndpoint.get({ url: { id: profileId } }) as Observable<Profile>
  createUnitPlanningProfile = (profile: Profile) => this.profileEndpoint.post(profile)
  updateUnitPlanningProfile = (profile: Profile) =>
    this.profileEndpoint.patch(profile, { url: { id: profile.id } })
  deleteUnitPlanningProfile = (profileId: number) =>
    this.profileEndpoint.delete({ url: { id: profileId } })

  getAllUnitPlanningProfileWorkloads = (profileId: number) =>
    this.profileWorkloadEndpoint.get({ url: { profileId: profileId } }) as Observable<
      ProfileWorkload[]
    >
  getUnitPlanningProfileWorkload = (profileId: number, workloadId: number) =>
    this.profileWorkloadEndpoint.get({
      url: { profileId: profileId, id: workloadId },
    }) as Observable<ProfileWorkload>
  updateUnitPlanningProfileWorkload = (profileId: number, workload: ProfileWorkload) =>
    this.profileWorkloadEndpoint.patch(workload, { url: { profileId: profileId, id: workload.id } })
}
