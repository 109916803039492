import { Unit } from "app/planner/models"
import { Deserialise, Field } from "@shared/decorators"
import { PIModel } from "@shared/models"

export class User extends PIModel {
  @Field() firstName = "<first name missing>"
  @Field() lastName = "<last name missing>"
  @Field() fullName = "<full name missing>"
  @Field() email = "<email missing>"
  @Field() dateJoined = new Date()
  @Field() lastLogin = new Date()
  @Field() isStaff = false
  @Field() isActive = true
  @Deserialise() units = [] as Unit[]
  @Deserialise() plannerUnits = [] as number[]

  //@todo: remove once supported by pi-components
  override serialise(): Record<string, any> {
    const data = super.serialise()
    const datelen = 10
    data["date_joined"] = this.dateJoined?.toISOString().slice(0, datelen)
    data["last_login"] = this.lastLogin?.toISOString().slice(0, datelen)
    return data
  }

  //@todo: remove once supported by pi-components
  override deserialise(data: any): this {
    super.deserialise(data)
    this.dateJoined = data.date_joined ? new Date(data.date_joined) : this.dateJoined
    this.lastLogin = data.last_login ? new Date(data.last_login) : this.lastLogin
    return this
  }
}
