import { Injectable } from "@angular/core"
import { MessageService } from "primeng/api"

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private messageService: MessageService) {}

  showSuccessMessage(message: string) {
    this.messageService.add({
      severity: "success",
      summary: $localize`:@@notificationService.success:Succes`,
      detail: message,
    })
  }

  showInfoMessage(message: string) {
    this.messageService.add({
      severity: "info",
      summary: $localize`:@@notificationService.info:Info`,
      detail: message,
    })
  }

  showErrorMessage(message: string) {
    this.messageService.add({
      severity: "error",
      summary: $localize`:@@notificationService.error:Fout`,
      detail: message,
      sticky: true,
    })
  }
}
