import { Deserialise, Field } from "@shared/decorators"
import { PIModel } from "@shared/models"
import { VersionStatus } from "./version-status.enum"

export class Version extends PIModel {
  @Field() status = VersionStatus.Pending
  @Deserialise() createdAt = new Date()

  override deserialise(data: any): this {
    super.deserialise(data)
    this.createdAt = data.created_at ? new Date(data.created_at) : this.createdAt
    return this
  }

  static override Sort(v1: Version, v2: Version): number {
    return v1.id - v2.id
  }
}
