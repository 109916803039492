import { Injectable } from "@angular/core"
import { BehaviorSubject, catchError, Observable, of, tap } from "rxjs"
import { Planning, Unit, Version } from "@planner/models"
import { PIModel } from "@shared/models"
import { PIEndpoint, PIEndpointBuilder } from "@shared/utils/endpoint-builder"
import { UnitService } from "./unit.service"
import { environment } from "@env"

@Injectable({
  providedIn: "root",
})
export class PlanningService {
  private endPoint: PIEndpoint<Planning>
  private _currentPlanning = new BehaviorSubject<Planning | undefined>(undefined)
  private _currentPlanningId = 0
  currentPlanning = this._currentPlanning.asObservable()
  versionEndPoint: PIEndpoint<Version>

  constructor(private endPointBuilder: PIEndpointBuilder, private unitService: UnitService) {
    this.endPoint = this.endPointBuilder.initialise(
      Planning,
      environment.api,
      (data: { isDetail: boolean }) => {
        if (data?.isDetail) {
          return `/units/${this.getCurrentUnitId()}/plannings/${this._currentPlanningId}`
        } else {
          return `/units/${this.getCurrentUnitId()}/plannings/`
        }
      },
    )
    this.versionEndPoint = this.endPointBuilder.initialise(
      Version,
      environment.api,
      (data: { id: number }) => {
        if (data?.id) {
          return `/units/${unitService.getCurrentUnitId()}/plannings/${this.getCurrentPlanningId()}/versions/${
            data.id
          }`
        } else {
          return `/units/${unitService.getCurrentUnitId()}/plannings/${this.getCurrentPlanningId()}/versions/`
        }
      },
    )
  }

  private getCurrentUnitId(): number {
    return this.unitService.getCurrentUnitId()
  }

  switchCurrentPlanningTo(planningId?: number): Observable<Planning | undefined> {
    /**
        Sets the current planning
        @param {number | undefined} planningId: the id of the requested current planning. Undefined is used to reset the planning
        @returns {Observable<Unit | undefined>} planning: the requested planning
        If the id is invalid, an error can be returned in the observable's error channel.
        */
    this._currentPlanningId = planningId ?? 0
    if (this._currentPlanningId === 0) {
      this._currentPlanning.next(undefined)
      return of(undefined)
    }
    if (this.getCurrentPlanning()?.id === this._currentPlanningId) {
      return of(this.getCurrentPlanning())
    }
    return this.getUnitPlanning().pipe(
      tap((value) => this._currentPlanning.next(value)),
      catchError((err) => {
        this._currentPlanningId = 0
        this._currentPlanning.next(undefined)
        throw err
      }),
    )
  }

  getCurrentPlanningId(): number {
    // Always use value of subject, not direct internal value since that is set before the observable can be retrieved
    return this._currentPlanning.getValue()?.id ?? 0
  }

  getCurrentPlanning(): Planning | undefined {
    /**
     * @returns the current value of the planning without subscribing for future updates
     */
    return this._currentPlanning.getValue()
  }

  getAllUnitPlannings = () => this.endPoint.get() as Observable<Planning[]>
  getUnitPlanning = () => this.endPoint.get({ url: { isDetail: true } }) as Observable<Planning>
  createUnitPlanning = (planning: Planning) => this.endPoint.post(planning)
  updateUnitPlanning = (planning: Planning) =>
    this.endPoint.patch(planning, { url: { isDetail: true } })
  deleteUnitPlanning = () => this.endPoint.delete({ url: { isDetail: true } })

  getAllUnitPlanningVersions = () => this.versionEndPoint.get() as Observable<Version[]>
  getUnitPlanningVersion = (versionId: number) =>
    this.versionEndPoint.get({ url: { id: versionId } }) as Observable<Version>
  createUnitPlanningVersion = () => this.versionEndPoint.post()
  updateUnitPlanningVersion = (version: Version) =>
    this.versionEndPoint.patch(version, { url: { id: version.id } })
  executeUnitPlanningVersion = (versionId: number) =>
    this.versionEndPoint.post(new PIModel(), { action: "/execute/", url: { id: versionId } })
}
